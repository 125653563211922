import CardLogo from "../../../components/ui/cards/CardLogo";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchHomeIndex} from "../../../services/newApi";
import Loader from "../../../components/Loader";
import ErrorWrapper from "../../../components/ui/wrappers/ErrorWrapper";

const LandingCards = ({className = ""}) => {
    const {
        isPending: isLoading,
        data,
        error,
        isError
    } = useQuery({
        queryKey: ["index-homepage"],
        queryFn: fetchHomeIndex,
    });


    if (isLoading) {
        return <div className={""}>
            <Loader/>
        </div>
    }

    if (error) {
        return <div className={""}>
            <ErrorWrapper error={error} tailwindClass={""}/>
        </div>
    }

    return <div className={className}>
        <CardLogo
            logoClass="FaUsers"
            title={parseInt(data.NbPreteurs).toLocaleString('fr-FR')}
            text="Prêteurs inscrits"
            isEmprunteur={false}
        />
        <CardLogo
            logoClass="FaPercent"
            title={parseFloat(data.TauxMoyenPret)?.toFixed(2) + "%"}
            text="Taux moyen des projets"
            isEmprunteur={false}
        />
        <CardLogo
            logoClass="GrValidate"
            title={data.NbProjetsFinances}
            text="Projets financés depuis 2015"
            isEmprunteur={false}
        />
    </div>
}

export default LandingCards;