import {useEffect, useRef} from "react";

const TrustPilotReview = () => {
    const trustBoxRef = useRef(null);

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustBoxRef.current, true);
        }
    }, []);

    return <section className="mb-16 mx-auto max-w-2xl px-4 sm:px-6 md:mb14 lg:max-w-7xl lg:px-8">
        <div ref={trustBoxRef} className="trustpilot-widget" data-locale="fr-FR" data-template-id="5406e65db0d04a09e042d5fc"
             data-businessunit-id="578f3a6e0000ff000592886e" data-style-height="28px" data-style-width="100%">
            <a href="https://fr.trustpilot.com/review/www.pretup.fr" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    </section>
}

export default TrustPilotReview;