import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Logo from '../assets/logo.svg';
import {useNavigate} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import Button from "./ui/buttons/Button";

const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const isEmprunteur = location.pathname.startsWith('/emprunteur');

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const openLoginModal = () => {
        setIsLoginModalOpen(true);
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    const openRegisterModal = () => {
        setIsRegisterModalOpen(true);
    };

    const closeRegisterModal = () => {
        setIsRegisterModalOpen(false);
    };

    const switchToRegisterModal = () => {
        closeLoginModal();
        openRegisterModal();
    };

    const switchToLoginModal = () => {
        closeRegisterModal();
        openLoginModal();
    };

    const onConnexionBtnClick = () => {
        window.location.assign(`${pretupClientSpace}/login.php`);
    }

    return (
        <header className='py-6 mb-12 border-b'>
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/">
                    <img src={Logo} alt="Pretup" className="h-8"/>
                </Link>
                <div className="hidden lg:flex items-center gap-6">
                    {isEmprunteur ? (
                        <>
                            <Link className="text-sm md:text-base lg:text-lg hover:text-primaryLight transition text-primaryDark" to="">
                                Emprunter auprès de particulier, comment ça marche ?
                            </Link>
                            <Link className="text-sm md:text-base lg:text-lg hover:text-primaryLight transition text-primaryDark" to="">
                                FAQ
                            </Link>
                            <Link className="text-sm md:text-base lg:text-lg hover:text-primaryLight transition text-primaryDark"
                                  to="/">
                                Prêter
                            </Link>
                        </>
                    ) : (
                        <>
                            <HashLink className="text-sm md:text-base lg:text-lg hover:text-secondaryLight transition text-secondary" to="/projets">
                                Projets à financer
                            </HashLink>
                            <Link
                                className="text-sm md:text-base lg:text-lg hover:text-secondaryLight transition text-secondary"
                                to="/emprunteur">
                                Emprunter
                            </Link>
                        </>
                    )}
                    <div className="">
                        <Button
                            btnType={"transparentSecondary"}
                            hover={true}
                            size={"small"}
                            text={"Connexion"}
                            link={{external: true, href: `${pretupClientSpace}/login.php`}}
                            tailwinClass={"mr-2"}
                        />
                        <Button
                            btnType={"secondary"}
                            size={"small"}
                            text={"Inscription"}
                            hover={true}
                            link={{external: false, href: 'inscription/preteur'}}
                        />

                    </div>
                </div>
                <div className="lg:hidden">
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
                        </svg>
                    </button>
                </div>
            </div>
            {isMenuOpen && (
                <div className="lg:hidden mt-4">
                    {isEmprunteur ? (
                        <>
                            <Link className="block py-2 px-4 hover:text-blue-900 transition text-primaryDark" to="">
                                Emprunter auprès de particulier
                            </Link>
                            <Link className="block py-2 px-4 hover:text-blue-900 transition text-primaryDark" to="">
                                FAQ
                            </Link>
                        </>
                    ) : (
                        <>
                            <HashLink className="block py-2 px-4 hover:text-blue-900 transition text-secondary"
                                      to="/projets">
                                Projets à financer
                            </HashLink>
                            <Link className="block py-2 px-4 hover:text-blue-900 transition text-secondary" to="">
                                Prêter
                            </Link>
                            <Link
                                className="block py-2 px-4 rounded-lg transition bg-secondary hover:bg-primaryDark text-white"
                                to="/emprunteur">
                                Emprunter
                            </Link>
                        </>
                    )}
                    <button
                        className="block py-2 px-4 bg-white hover:bg-secondaryLight text-black rounded-lg transition"
                        onClick={onConnexionBtnClick}>
                        Connexion
                    </button>
                    <button
                        className="block py-2 px-4 bg-white hover:bg-secondaryLight text-black rounded-lg transition"
                        onClick={() => {
                            navigate('inscription/preteur')
                        }}>
                        Inscription
                    </button>
                </div>
            )}
            {/*<Login isOpen={isLoginModalOpen} onRequestClose={closeLoginModal}*/}
            {/*       openRegisterModal={switchToRegisterModal}/>*/}
            {/*<Register isOpen={isRegisterModalOpen} setIsOpen={setIsRegisterModalOpen} onRequestClose={closeRegisterModal} openLoginModal={switchToLoginModal} />*/}
        </header>
    );
}

export default Header;