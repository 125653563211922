import {useLocation} from "react-router-dom";
import Buttons from "../../../components/Buttons";
import {helpButtons, returnMethodsBtn as returnBtn} from "../../../constants/buttons";
import AProposTeam from "./AProposTeam";
import ContactForm from "../../../components/ContactForm";


/**
 * Le contenu des boutons à afficher suivant les routes.
 * @type {{"/preter/methodes-de-cotation": [{action: string, content: string},{action: string, content: string}], "/preter/methodes-de-cotation/tarification": [{action: string, content: string},{action: string, content: string}], "/preter/methodes-de-cotation/organes-de-decision": {action: string, content: string}[], "/preter/methodes-de-cotation/selection-des-pme": [{action: string, content: string},{action: string, content: string}], "/preter/methodes-de-cotation/cotation": [{action: string, content: string},{action: string, content: string}], "/preter/methodes-de-cotation/analyse-financiere": [{action: string, content: string},{action: string, content: string}]}}
 */
const routesBtns = {
    '/preter/methodes-de-cotation': helpButtons,
    '/preter/methodes-de-cotation/selection-des-pme': [
        returnBtn,
        {
            content: 'Suivant: Anaylse financière',
            action: '/preter/methodes-de-cotation/analyse-financiere'
        }
    ],
    '/preter/methodes-de-cotation/analyse-financiere': [
        returnBtn,
        {
            content: 'Cotation',
            action: '/preter/methodes-de-cotation/cotation'
        }
    ],
    '/preter/methodes-de-cotation/cotation': [
        returnBtn,
        {
            content: 'Tarification',
            action: '/preter/methodes-de-cotation/tarification'
        }
    ],
    '/preter/methodes-de-cotation/tarification': [
        returnBtn,
        {
            content: 'Organes de décision',
            action: '/preter/methodes-de-cotation/organes-de-decision'
        }
    ],
    '/preter/methodes-de-cotation/organes-de-decision': [
        returnBtn,
    ]
}

const routesTitles = {
    '/preter/methodes-de-cotation': "Besoin d'aide supplémentaire ?",
    '/preter/methodes-de-cotation/selection-des-pme': 'Continuer',
    '/preter/methodes-de-cotation/analyse-financiere': 'Continuer',
    '/preter/methodes-de-cotation/cotation': 'Continuer',
    '/preter/methodes-de-cotation/tarification': 'Continuer',
    '/preter/methodes-de-cotation/organes-de-decision': 'Continuer'
}
const BottomAdditionalContent = ({content}) => {
    const location = useLocation();

    return (
        <div className="mb-16">
            {content === 'formContact' &&
                <ContactForm />
            }
            {content === 'btns' && routesBtns.hasOwnProperty(location.pathname) &&
                <Buttons
                    title={routesTitles[location.pathname]}
                    buttons={routesBtns[location.pathname]}
                />
            }
        </div>
    )
}

export default BottomAdditionalContent;