import HeroBlog from "./components/HeroBlog";
import {useQuery} from "@tanstack/react-query";
import {getBlogArticles} from "../../services/newApi";
import Loader from "../../components/Loader";
import ErrorWrapper from "../../components/ui/wrappers/ErrorWrapper";
import CardArticle from "./components/CardArticle";
import H2Base from "../../components/ui/titles/H2Base";
import {useNavigate} from "react-router-dom";

const HomeBlog = () => {
    const {
        isPending: isLoading,
        data: articles,
        error,
        isError
    } = useQuery({
        queryKey: ["blog-articles-preview"],
        queryFn: async () => getBlogArticles(0, 1),
    });
    const navigate = useNavigate();

    if (isLoading) {
        return <div className="mb-12">
            <Loader/>
        </div>
    }

    if (isError) {
        return <div className="mb-12">
            <ErrorWrapper error={error?.message || ""}/>
        </div>
    }

    if (articles.length === 0) {
        return <div className="mb-12">
            <ErrorWrapper error={"Aucun article trouvé"}/>
        </div>
    }

    const handleCardClick = (idArticle) => {
        navigate(`/blog/articles/${idArticle}`);
    }

    return <div className={"mb-8 md:mb-12 mx-auto"}>
        <HeroBlog article={articles[0]} handleClick={handleCardClick} />
        <H2Base text={"Derniers articles"}/>
        <div className={'max-w-screen-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10'}>
            {articles.map((article, index) => <CardArticle
                article={article}
                key={`cardArticle-${index}-${article.idArticle}`}
                handleClick={handleCardClick}
            />)
            }
        </div>
    </div>
}

export default HomeBlog;