/**
 * @param className
 * @param IconElement
 * @param href
 * @returns {JSX.Element}
 * @constructor
 */
const IconLink = ({
    className,
    IconElement,
    href,
    iconSize
                     }) => {
    if (typeof  className !== 'string') {
        className = "";
    }

    return <a href={href} target="_blank" className={className}>
        <IconElement size={iconSize} />
    </a>
}

export default IconLink;