import React from "react";
import {Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import Home from './pages/Home/Home';
import HomeEmprunteur from './pages/home-emprunteur/HomeEmprunteur';
import Register from "./components/Register";
import GenericTemplate from "./pages/generic-template/GenericTemplate";
import ScrollToTop from "./components/ScrollToTop";
import BoostPretup from "./pages/boost-pretup/BoostPretup";
import Faq from "./pages/faq/Faq";
import Preter from "./pages/preter/Preter";
import ContactForm from "./components/ContactForm";
import Tutorials from "./pages/tutorials/Tutorials";
import Tutorial from "./pages/tutorial/Tutorial";
import Loader from "./components/Loader";
import Projects from "./pages/projects/Projects"
import NotFound from "./components/NotFound";
import LoginRedirect from "./components/LoginRedirect";
import Landing from "./pages/landing/Landing";
import HomeBlog from "./pages/home-blog/HomeBlog";
import ArticleBlog from "./pages/article-blog/ArticleBlog";

const Statistics = React.lazy(() => import("./pages/statistics/Statistics"));
const App = () => {
    return (
        <div className='max-w-[1440px] mx-auto'>
            <Header/>
            <div className="px-4">
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/emprunteur" element={<HomeEmprunteur/>}/>
                    {/*<Route path="/inscription" element={<Home />} />*/}
                    <Route path="/inscription/preteur" element={<Register/>}/>
                    <Route path="/preter/ouvrez-votre-compte-pretup" element={<Register/>}/>
                    <Route path="/contact" element={<ContactForm className/>}/>
                    <Route path="/lexique" element={<GenericTemplate/>}/>
                    <Route path="/taux-de-rendement-interne" element={<GenericTemplate/>}/>
                    <Route path="/mentions-legales" element={<GenericTemplate/>}/>
                    <Route path="/nous-recrutons" element={<GenericTemplate/>}/>
                    <Route path="/politique-de-confidentialite" element={<GenericTemplate/>}/>
                    <Route path="/a-propos-de" element={<GenericTemplate/>}/>
                    <Route path="/actualites" element={<GenericTemplate/>}/>
                    <Route path="/partenaires" element={<GenericTemplate/>}/>
                    <Route path="/cgu" element={<GenericTemplate/>}/>
                    <Route path="/preter/tutoriels" element={<Tutorials/>}/>
                    <Route path="/preter/tutoriel/1" element={<Tutorial/>}/>
                    <Route path="/preter/tutoriel/2" element={<Tutorial/>}/>
                    <Route path="/preter/tutoriel/3" element={<Tutorial/>}/>
                    <Route path="/preter/tutoriel/4" element={<Tutorial/>}/>
                    <Route path="/preter/tutoriel/5" element={<Tutorial/>}/>
                    <Route path="/preter/tutoriel/6" element={<Tutorial/>}/>
                    <Route path="/projets" element={<Projects/>} />
                    <Route path="/reclamation" element={<GenericTemplate/>}/>
                    <Route path="/communique-de-presse" element={<GenericTemplate/>}/>
                    <Route path="/preter/methodes-de-cotation" element={<GenericTemplate/>}/>
                    <Route path="/preter/methodes-de-cotation/selection-des-pme" element={<GenericTemplate/>}/>
                    <Route path="/preter/methodes-de-cotation/analyse-financiere" element={<GenericTemplate/>}/>
                    <Route path="/preter/methodes-de-cotation/cotation" element={<GenericTemplate/>}/>
                    <Route path="/preter/methodes-de-cotation/tarification" element={<GenericTemplate/>}/>
                    <Route path="/preter/methodes-de-cotation/organes-de-decision" element={<GenericTemplate/>}/>
                    <Route path="/preter/methodes-de-cotation/suivi-des-financements" element={<GenericTemplate/>}/>
                    <Route path="/garanties" element={<GenericTemplate/>}/>
                    <Route path="/fiscalite" element={<GenericTemplate/>}/>
                    <Route path="/avis-clients" element={<GenericTemplate/>}/>
                    <Route path="/financement-solidaire---associations" element={<GenericTemplate/>}/>
                    <Route path="/preter/faq-preteur" element={<Faq/>}/>
                    <Route path="/emprunter/faq-emprunteur" element={<Faq/>}/>
                    <Route path="preter/preter-a-des-entreprises" element={<Preter/>}/>
                    {/*<Route path="/PGE" element={<GenericTemplate />} />*/}
                    <Route path="/pret-automatique-boost-pretup" element={<BoostPretup/>}/>
                    <Route path="/dynamisez-votre-epargne-jusqu-a-11-pourcent" element={<Landing />}/>
                    <Route
                        path="/statistiques"
                        element={
                            <React.Suspense fallback={<Loader/>}>
                                <Statistics/>
                            </React.Suspense>
                        }
                    />
                    <Route path="/login.php" element={<LoginRedirect />}  />
                    <Route path="*" element={<NotFound />} />
                    <Route path={"/blog"} element={<HomeBlog />} />
                    <Route path={"/blog/articles/:id"} element={<ArticleBlog />} />
                </Routes>
            </div>
            <Footer/>
        </div>
    )
}

export default App;