const BorderWrapper = ({tailwindClass, children, onClick}) => {
    let finalClass = "border border-gray-200 rounded-lg shadow ";

    if (typeof tailwindClass !== 'string') {
        tailwindClass = '';
    }
    finalClass += tailwindClass;

    return <div className={finalClass} onClick={onClick}>{children}</div>;
}

export default BorderWrapper;