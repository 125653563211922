import {useState} from "react";

const Input = ({type, placeholder, value, onChange, tailwindClass}) => {
    const [internalValue, setInternalValue] = useState("");

    if (!placeholder) {
        placeholder = "";
    }

    if (type !== "number" && type !== "text") {
        type = "text";
    }

    if (typeof tailwindClass !== "string") {
        tailwindClass = "";
    }

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (onChange) {
            onChange(newValue);
        } else {
            setInternalValue(newValue);
        }
    };

    const inputValue = onChange ? value : internalValue;

    const finalClass = "mt-1 w-full block py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm " + tailwindClass;

    return (
        <input type={type} placeholder={placeholder} value={inputValue} onChange={handleChange}
               className={finalClass}
        />
    );
}

export default Input;