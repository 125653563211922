import Input from "../../../components/ui/Input";
import Button from "../../../components/ui/buttons/Button";
import {IoIosSearch} from "react-icons/io";
import {getProjects, searchProjects} from "../../../services/newApi";
import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useQueryClient} from "@tanstack/react-query";


const ProjectSearch = ({setTabContent, setActiveTab, setTabError, setIsSearching, setIsTabButtonVisible, searchInputValue, setSearchInputValue}) => {
    const { refetch } = useQuery({
        queryKey: ["project-search"],
        queryFn: async => searchProjects(searchInputValue),
        enabled: false,
    });

    const handleSearchBtnClick = async () => {
        setActiveTab(null);
        setIsSearching(true);

        const response = await refetch();

        setIsSearching(false);
        setIsTabButtonVisible(false);

        if (response.data) {
            setTabContent(response.data);
            setTabError(null);
        } else if (response.error) {
            console.error("Erreur lors de la recherche :", response.error);
            setTabError(response.error || {message: "Une erreur est survenue."});
        }
    };

    const handleSearchInputChange = (value) => {
        setSearchInputValue(value);
    }

    return (
        <div className="block sm:flex sm:items-end md:max-h-10 md:gap-x-5">
            <p className="mb-6 text-center text-lg font-bold min-w-52 sm:mb-0 sm:tet-right lg:inline-block">Rechercher
                un projet :</p>
            <div className="flex flex-col items-center gap-y-2 sm:flex-row sm:gap-x-3 sm:items-end">
                <Input type="text" onChange={handleSearchInputChange} value={searchInputValue}
                       tailwindClass="max-w-52"/>
                <Button
                    btnType="secondaryBorder"
                    tailwinClass="px-2 sm:px-2 md:px-2 lg:px-2 xl:px-2 py-1 sm:py-1 md:py-1 lg:py-1 xl:py-1 text-base sm:text-base md:text:base lg:text-base xl:text-base"
                    icon={<IoIosSearch size={25}/>}
                    iconPosition="only"
                    hover={true}
                    handleClick={handleSearchBtnClick}/>
            </div>
        </div>
    );
}

export default ProjectSearch;