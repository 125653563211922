import {useNavigate} from "react-router-dom";

const CardImage = ({image, title, content, width, link}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (link) {
            navigate(link);
        }
    }

    return (
        <div
            style={{
                width: width,
            }}
            onClick={handleClick}
            className={link ?
                `flex flex-col w-full border border-gray-200 rounded-lg shadow cursor-pointer hover:brightness-125`
                :
                `flex flex-col w-full border border-gray-200 rounded-lg shadow`
            }>
            <img className="w-full h-3/5 object-cover" src={image} alt={title}/>
            <div className="p-3 flex flex-col flex-1 justify-evenly">
                <h5 className="text-2xl font-bold tracking-tight rounded-lg text-secondary">{title}</h5>
                <p>{content}</p>
            </div>

        </div>
    )
}
export default CardImage;