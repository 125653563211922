export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

/**
 * Trouve le breakpoint adapté à ceux définis dans tailwind.config.js
 * en fonction de la taille de l'écran.
 *
 * @param windowSize number La taille de l'écran
 */
export const getBreakPoint = (windowSize) => {
    let currentBreakpoint = "";

    if (windowSize < 640) {
        currentBreakpoint = "sm-"; // mobile petit écran
    } else if (windowSize >= 640 && windowSize < 768) {
        currentBreakpoint = "sm"; // mobile
    } else if (windowSize >= 768 && windowSize < 1024) {
        currentBreakpoint = "md"; // tablette
    } else if (windowSize >= 1024 && windowSize < 1234) {
        currentBreakpoint = "lg"; // PC
    } else if (windowSize >= 1234) {
        currentBreakpoint = "xl"; // PC avec écran beaucoup trop large pour un site internet :)
    } else {
        currentBreakpoint = "nique ta mère"; // cas par défaut
    }

    return currentBreakpoint;
}

export const formatDate = (dateString, withHours = false) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    let formattedDate = `${day}/${month}`;

    if (withHours) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        formattedDate += ` à ${hours}:${minutes}`;
    } else {
        formattedDate += `/${year}`;
    }

    return formattedDate;
};

export const formatDatasProject = (project) => {
    return {
        ...project,
        DebutCollecte: formatDate(project.DebutCollecte.date),
        FinCollecte: formatDate(project.FinCollecte.date),
        DebutCollecteWithHours: formatDate(project.DebutCollecte.date, true),
        FinCollecteWithHours: formatDate(project.FinCollecte.date, true),
    }
}
