import {useState} from "react";
import {sendContactForm} from "../services/api";

const ContactForm = (props) => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await sendContactForm(formData);

        if (response) {
            setSuccess("Votre demande de contact a bien été transmise à l'équipe Pretup et sera traitée au plus vite.");
            setFormData({
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                message: '',
            });
        } else {
            setError("Impossible de contacter l'équipe de Pretup pour le moment. Réessayez plus tard");
        }

        setIsLoading(false);
    }

    const handleChange = (e) => {

        const {id, value} = e.target;
        setFormData({
            ...formData,
            [id]: value
        });

    };

    return (
        <div className="mb-8">
            <h2 className="text-4xl text-secondary font-bold">Contactez-nous</h2>
            <form onSubmit={handleSubmit}>
                <div className="mt-6 grid gap-6 mb-6 md:grid-cols-2">
                    <div className="col-span-2 md:col-span-1">
                        <label htmlFor="firstname"
                               className="block mb-2 text-sm font-medium text-gray-900">Prénom</label>
                        <input type="text" id="firstname"
                               value={formData.firstname}
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Prénom" onChange={handleChange} required/>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                        <label htmlFor="lastname"
                               className="block mb-2 text-sm font-medium text-gray-900">Nom</label>
                        <input type="text" id="lastname"
                               value={formData.lastname}
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Nom" onChange={handleChange} required/>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                        <label htmlFor="email"
                               className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                        <input type="email" id="email"
                               value={formData.email}
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Email" onChange={handleChange} required/>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                        <label htmlFor="phone"
                               className="block mb-2 text-sm font-medium text-gray-900">Téléphone</label>
                        <input type="tel" id="phone"
                               value={formData.phone}
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Téléphone"
                               onChange={handleChange}
                        />
                    </div>
                    <div className=" col-span-2">
                        <label htmlFor="message"
                               className="block mb-2 text-sm font-medium text-gray-900">Message</label>
                        <textarea id="message" rows="4"
                                  value={formData.message}
                                  name="message"
                                  className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-primary focus:ring-primaryDark focus:border-primaryDark"
                                  onChange={handleChange} required
                                  placeholder="Votre message ici..."></textarea>
                    </div>
                </div>
                {error &&
                    <div className="my-3 col-span-2 text-center text-red-500 text-lg md:text-xl">
                        ⚠ {error} ⚠
                    </div>
                }
                {success &&
                    <div className="my-3 col-span-2 text-center text-green-500 text-lg md:text-xl">
                        {success}
                    </div>
                }
                <button type="submit"
                        className="text-white bg-secondary hover:bg-secondaryLight focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        disabled={isLoading}
                >
                    Envoyer
                </button>
            </form>
        </div>
    );
}

export default ContactForm;