import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../assets/logo_emprunteur.svg';
import {FaEuroSign} from "react-icons/fa";
import {FaBullhorn} from "react-icons/fa";
import {FaClock} from "react-icons/fa";
import { FaArrowTrendUp } from "react-icons/fa6";
import {Link} from "react-router-dom";

const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.8,
            staggerChildren: 0.2
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const CardEmprunteur = () => {
    return (
        <div className="container mb-12 mx-auto md:mb14 xl:mb-24">
            <motion.div
                className="flex flex-wrap bg-white border-2 border-primary rounded-lg p-8 shadow-2xl"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <motion.div
                    className="w-full sm:w-1/3 px-4 mb-8 flex flex-col items-center text-center"
                    variants={itemVariants}
                >
                    <img src={Logo} alt="logo_emprunteur" className="w-30 h-30 mb-4"/>
                    <h2 className="text-2xl font-bold text-black mb-4">
                        Chefs d'entreprises: développez, embauchez du personnel et financez vos investissements.
                    </h2>
                    <p className="text-gray-600 mb-6">
                        En mettant directement les internautes en contact avec les entreprises, nous rendons possible le financement de vos projets.
                    </p>
                    <Link to="/emprunteur"
                       className="bg-primary hover:bg-primaryLight text-white font-semibold px-6 py-3 rounded transition duration-300 ease-in-out transform hover:scale-105">
                        Emprunter
                    </Link>
                </motion.div>
                <motion.div
                    className="w-full sm:w-2/3 px-4 flex flex-wrap items-center justify-center"
                    variants={containerVariants}
                >
                    {[{ icon: <FaEuroSign />, text: "Obtenez un financement rapide" }, { icon: <FaBullhorn />, text: "Faites connaître votre entreprise" }, { icon: <FaClock />, text: "Dossier en ligne, réponse en 48h" }, {icon: <FaArrowTrendUp />, text: "Financez votre croissance"}].map((item, index) => (
                        <motion.div
                            key={index}
                            className="w-1/2 px-4 mb-8 flex flex-col items-center text-center"
                            variants={itemVariants}
                        >
                            <div className="text-primary rounded-full p-4 mb-4 text-4xl">
                                {item.icon}
                            </div>
                            <p className="text-lg font-medium text-gray-700">
                                {item.text}
                            </p>
                        </motion.div>
                    ))}
                </motion.div>
            </motion.div>
        </div>
    )
}

export default CardEmprunteur;