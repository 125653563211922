import {useState} from "react";
import Simulator from "./components/Simulator";
import {getSimulatorData} from "../../services/newApi";
import {useQuery} from "@tanstack/react-query";

const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;

const Preter = () => {
    const [simulatorAmount, setSimulatorAmount] = useState(100);
    const {
        isPending: isSimulatorLoading,
        data: simulatorData,
        error: errorSimulator,
        isError,
    } = useQuery({
        queryKey: ["simulator", simulatorAmount],
        queryFn: ({queryKey}) => getSimulatorData(queryKey[1]),
    });
    const handleSignSimulatorClick = (sign) => {
        let amount = simulatorAmount;

        setSimulatorAmount((prevState) => {
            if (sign === '+') {
                if (prevState === 20) {
                    amount = 100;
                } else {
                    amount = prevState + 100;
                }
            } else {
                if (prevState === 100) {
                    amount = 20;
                } else if (prevState === 20) {
                    amount = prevState;
                } else {
                    amount = prevState - 100;
                }
            }
            return amount;
        })
    };

    return (
        <>
            <div className="mx-auto mb-20 w-full lg:flex lg:items-center lg:gap-8 lg:w-2/3">
                <div className="mb-4 text-lg text-secondary lg:mb-0 lg:w-1/2">
                    En mettant directement les prêteurs en contact avec les entreprises, nous rendons
                    possible le financement de l'économie réelle tout en offrant un rendement attractif au Prêteur.
                </div>
                <div className="w-full lg:w-1/2 h-80">
                    <iframe
                        loading="lazy"
                        className="w-full h-full"
                        src="https://www.youtube.com/embed/NfHQz7qJgwY"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
            <div className="mb-16">

                <h2 className="base-h2-secondary mb-12">Les étapes du financement</h2>


                <div className="mb-16 md:flex md:flex-wrap md:justify-center md:gap-x-5 md:gap-y-8">
                    {[...Array(6)].map((_, index) => (
                        <div
                            className="w-100 mb-3 p-3 bg-secondary border-gray-200 rounded-lg shadow text-white md:mb-0 md:w-[calc(50%-0.75rem)] lg:w-[calc(33%-1.5rem)]">
                            <div className="text-2xl font-bold">{index + 1}</div>
                            <div className="flex items-center">
                                <div className="w-10/12 mb-2 text-xl text-center font-bold rounded-lg py-1 p-3">
                                    {[
                                        'Création du compte',
                                        'Choix des projets',
                                        'Offre de prêt',
                                        'Contrat de prêt',
                                        'Remboursement mensuel',
                                        'Réinvestissement ou encaissement'
                                    ][index]}
                                </div>
                                <img className={'max-h-11 flex '} src={`/images/icons/pret-${index + 1}.svg`}
                                     alt={`Étape ${index + 1}`}/>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mb-16 text-center">

                    <a className="btn btn--secondary" href={`${pretupClientSpace}/login.php`} >
                        Prêtez maintenant !
                    </a>
                </div>

                <div className="avantage-crowdlending">
                    <h2 className='base-h2-secondary mb-11 text-center'>Les avantages du financement participatif</h2>
                    <div className="flex flex-wrap gap-y-5 md:justify-between md:gap-x-5">
                        {[
                            {
                                title: 'Diversification de l’investissement',
                                description: 'Je complète mon épargne classique par un investissement direct aux entreprises',
                                image: '/images/icons/diversification.svg'
                            },
                            {
                                title: 'Solidarité',
                                description: 'Je soutiens l\'économie locale et les entreprises créatrices d\'emplois',
                                image: '/images/icons/solidarite.svg'
                            },
                            {
                                title: 'Rentabilité',
                                description:
                                    'Je bénéficie de taux d\'intérêt supérieur à l\'épargne classique. Je n\'ai aucun frais de gestion à payer, l\'accès à la plateforme est entièrement gratuit',
                                image: '/images/icons/rentabilite.svg'
                            },
                            {
                                title: 'Liberté',
                                description:
                                    'Je choisis où vont mes investissements et j\'ai la main sur mon portefeuille d\'épargne',
                                image: '/images/icons/liberte.svg'
                            }
                        ].map((advantage, index) => (
                            <div className="card w-full md:w-[calc(50%-1.25rem)] lg:w-[calc(25%-3.75rem)]" key={index}>
                                <div className="flex flex-col items-center">
                                    <div className='mb-4 min-h-24'>
                                        <img className="w-16" src={advantage.image} alt={advantage.title}/>
                                    </div>
                                    <h3 className="card-h3 mb-2">{advantage.title}</h3>
                                    <p className="text-center">{advantage.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mb-16 p-5 hidden text-center bg-secondaryLight md:block">
                <h2 className='base-h2-white mb-8 text-center'>Les étapes du financement</h2>
                <div className="sous-texte">
                    Pretup vous propose uniquement des projets préalablement étudiés et validés par une équipe
                    financière.
                </div>
                <div className="sous-texte">
                    La fiche projet vous permet d'accéder à toutes les informations sur la société, l'objet et le
                    montant du financement facilitant la prise de décision
                </div>
                <div className="relatif bloc-explicatif">
                    <div className="relatif bloc-explicatif-schema">
                        <img
                            className="img-responsive"
                            style={{margin: '30px auto'}}
                            src="/images/pages/preter/picto_choix_projet.png"
                            alt="Comment choisir un projet ?"
                        />
                        <p className="hidden">Photographie de l'entreprise en recherche de financement</p>
                        <p className="hidden">Localisation de l'entreprise</p>
                        <p className="hidden">Durée de remboursement</p>
                        <p className="hidden">Le montant de l'emprunt</p>
                        <p className="hidden">Objet du financement</p>
                        <p className="hidden">Taux d'intérêt du Projet (fixé par la Plateforme)</p>
                        <p className="hidden">Nombre de jours restants avant la fin de la collecte</p>
                    </div>
                </div>
                <div className="bloc-explicatif-text">
                    Chaque investissement possède un rendement compris entre 6 et 11% par an en fonction de la
                    notation de l'entreprise. Vous encaissez, dès le mois qui suit le début du prêt, le
                    remboursement
                    d'une partie de capital ainsi que les intérêts. Vous pouvez alors réinvestir l'argent accumulé
                    sur
                    de nouveaux projets ou demander le remboursement sur votre compte bancaire.
                </div>
            </div>
            <Simulator
                datas={simulatorData}
                handleBtnsClick={handleSignSimulatorClick}
                amount={simulatorAmount}
                setAmount={setSimulatorAmount}
                isLoading={isSimulatorLoading}
                error={errorSimulator}
            />

        </>
    );
}

export default Preter;