const Pastille = ({className, children}) => {
    let finalClass = "block rounded-2xl w-fit h-fit font-black ";

    if (typeof className === "string") {
        finalClass += className;
    }

    return <span className={finalClass}>{children}</span>
}

export default Pastille;