import Button from "../../../components/ui/buttons/Button";
import BorderWrapper from "../../../components/ui/wrappers/BorderWrapper";

const CTASubscription = () => {
    return <BorderWrapper tailwindClass="mb-8 p-4 max-w-96">
        <p className="text-lg md:text-xl mb-5">Prêtez directement aux entreprises dès aujourd'hui</p>
        {/*<p className="text-sm">Récupérez jusqu'à 11% d'intérêt</p>*/}
        <div className="">
            <Button
                text="Inscription gratuite"
                size="small"
                btnType="secondaryBorder"
                hover={true}
                link={{external: false, href: '/inscription/preteur'}}
                tailwinClass=""
            />
        </div>
    </BorderWrapper>
}

export default CTASubscription;