export const footerMenuStaticItems = [
    {
        titreRubrique: "Liens utiles",
        Titre: "Mentions légales",
        url: "mentions-legales"
    },
    {
        titreRubrique: "Liens utiles",
        Titre: "Politique de confidentialité",
        url: "politique-de-confidentialite"
    },
    {
        titreRubrique: "Liens utiles",
        Titre: "CGU",
        url: "cgu"
    },
    {
        titreRubrique: "Liens utiles",
        Titre: "CGU Lemonway",
        url: "cgu_lemonway"
    },
    {
        titreRubrique: "Liens utiles",
        Titre: "Blog",
        url: "/blog"
    },
];