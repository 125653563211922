import GridCustom from "../../Home/components/GridCustom";
import Loader from "../../../components/Loader";
import {useEffect, useState} from "react";
import Button from "../../../components/ui/buttons/Button"
import ProjectCard from "../../Home/components/ProjectCard";

const TabContent = ({isLoading, error, content, isTabButtonVisible}) => {
    const [btnText, setBtnText] = useState("Voir plus");
    const [items, setItems] = useState([]);
    // Le compteur qui s'incrémente après un clic sur le bouton.
    const [cpt, setCpt] = useState(0);

    useEffect(() => {
        if (content.length > 0) {
            setItems(content.slice(0, 8));
        }

        return () => {
            setItems([]);
            setCpt(0);
            setBtnText("Voir plus");
        }
    }, [content])

    const handleBtnClick = () => {
        const clics = cpt+1;

        switch (clics) {
            case 1:
                setItems(content.slice(0, 16));
                break;
            case 2:
                setBtnText("Afficher tout");
                setItems(content.slice(0, 32));
                break;
            case 3:
                setItems(content);
                break;
        }
        setCpt(clics);
    }

    const handleDisplayBtn = () => {
        if (!isTabButtonVisible) return false;

        switch (cpt) {
            case 0:
                return items.length >= 8 ? true : false;
            case 1:
                return items.length >= 16 ? true : false;
            case 2:
                return items.length >= 32 ? true : false;
            // case 3:
            //     return items.length >= 64 ? true : false;
        }
    }

    if (error) {
        return <div className="text-xl min-h-72 flex items-center justify-center">{error}</div>;
    }

    if (isLoading) {
        return <Loader/>;
    }


    return content.length > 0 ?
        <div className="mb-12">
            <GridCustom items={items} containerStyle="mb-8" config={{maxColumnWidth: 300, gap: 30}}/>
            {/*<div className="grid gap-x-[30px] grid-cols-[repeat(1,minmax(0,300px))] sm:grid-cols-[repeat(2,minmax(0,300px))] md:sm:grid-cols-[repeat(3,minmax(0,300px))] lg:sm:grid-cols-[repeat(4,minmax(0,300px))]">*/}
            {/*    {items.map((item, index) => (<ProjectCard project={item} key={index} />))}*/}
            {/*</div>*/}
            {handleDisplayBtn() &&
                <div className="text-center">
                    <Button text={btnText} btnType="secondary"
                            tailwinClass={cpt >= 3 ? "min-w-52 d-none" : "min-w-52"}
                            className="min-w-5"
                            handleClick={handleBtnClick}
                    />
                </div>
            }
        </div>
        :
        <div className="min-h-96 flex items-center justify-center">
            <p className="text-2xl text-center font-bold">Aucun résultat</p>
        </div>
}
export default TabContent;