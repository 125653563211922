import React, {useState} from "react";
import {
    calculateProgress, generateDurationText,
    getProgressBarColor,
    getRiskClassColor
} from "../functions/projectCardFunctions";
import ButtonProjectCard from "../../../components/ui/buttons/ButtonProjectCard";
import Pastille from "../../../components/ui/wrappers/Pastille";

const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;

const ProjectCard = ({project, index}) => {
    const generateDateElement = () => {
        if (project.etat) {

            switch (project.etat) {
                case "avenir":
                    return <p>Début de collecte : <span className="float-right font-bold text-secondary">{project.DebutCollecteWithHours}</span></p>;
                case "encours":
                case "termine":
                case "rembourse":
                default:
                    return <p>Fin de collecte : <span className="float-right">{project.FinCollecte}</span></p>;
                    break;
            }
        }
    }
    const [imgSrc, setImgSrc] = useState(`/images/projets/${project.ProjetId}.jpg`);
    return <div
        key={index}
        className="group hover:opacity-75 relative px-2 cursor-pointer"
        onClick={() => window.open(`${pretupClientSpace}/${project.URLRewrite}-${project.ProjetId}`, '_blank')}
    >
        <div
            className="relative w-full h-80 overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75">
            {project.Pastilles && project.Pastilles.length > 0 && (
                <div className="absolute left-3 top-3">
                    {
                        project.Pastilles.map(((text, index) => {
                            const theClass = index === 0 ?
                                "text-sm bg-white text-secondary py-1 px-2 text-sm"
                                :
                                "mt-2 text-sm bg-white text-secondary py-1 px-2 text-sm";

                            return <Pastille className={theClass}
                                         key={`pastillesProj-${project.ProjetId}-${index}`}>{text}</Pastille>
                        }))
                    }
                </div>
            )}


            <span
                className="py-1 px-2 absolute right-3 top-3 block w-fit text-sm bg-white text-secondary font-black rounded-2xl">n° {project.Position}</span>
            <img
                src={imgSrc}
                onError={()=> setImgSrc(project.Photo) }
                alt={project.NomProjet}
                className="h-full w-full object-cover object-center"
            />
            <ButtonProjectCard
                className={"text-lg border-0 px-5 py-2 absolute bottom-2 left-1/2 -translate-x-1/2"}
                content={"Investir"}
                projectType={project.etat}
            />
        </div>
        <div className="mt-2 w-full bg-gray-300 rounded-full h-2.5 dark:bg-gray-700">
            <div
                className={`h-2.5 rounded-full ${getProgressBarColor(calculateProgress(project.MontantRecolte, project.MontantRecherche))}`}
                style={{width: `${calculateProgress(project.MontantRecolte, project.MontantRecherche)}%`}}></div>
        </div>
        <p className="mt-1 text-xs text-gray-600">{calculateProgress(project.MontantRecolte, project.MontantRecherche).toFixed(0)}%
            financé {generateDurationText(project.FinanceEn)}<span
                className="float-right">{project.NbPreteurs} prêteurs</span></p>
        <div className="mt-4">
            <div className="flex justify-between items-center">
                <h3 className="text-sm text-gray-700 font-bold">
                    {project.NomProjet}
                </h3>
                <p className="text-sm font-bold text-gray-900">{project.MontantRecherche?.toLocaleString('fr-FR')}€</p>
            </div>
            <hr className="border-secondary"></hr>
            <div className="mt-1 text-sm text-gray-500">
                <p>Taux d'intérêt : <span className="text-black float-right font-black">{project.TauxInteret}%</span>
                </p>
                {generateDateElement()}
                <p>Durée du prêt : <span className="text-black float-right font-black">{project.Duree} mois</span></p>
                <p>Classe de risque : <span
                    className={`mt-1 text-sm ${getRiskClassColor(project.ClasseRisque)} float-right`}>{project.ClasseRisque}</span>
                </p>
            </div>
        </div>
    </div>
}

export default ProjectCard;