import {useState} from "react";
import {FaEye, FaEyeSlash} from 'react-icons/fa';

const CompanyRegisterForm = ({
                                 lastName,
                                 setLastName,
                                 firstName,
                                 setFirstName,
                                 email,
                                 setEmail,
                                 password,
                                 setPassword,
                                 confirmPassword,
                                 setConfirmPassword,
                                 birthdate,
                                 setBirthdate,
                                 handleSubmit,
                                 error,
                                 setError,
                                 inscriptionOk,
                                 setInscriptionOk,
                                 promoCode,
                                 setPromoCode,
                                 sponsorCode,
                                 setSponsorCode,
                                 siren,
                                 setSiren,
                                 companyName,
                                 setCompanyName,
                                 isCguAccepted,
                                 setIsCguAccepted,
                                 isLemonwayCguAccepted,
                                 setIsLemonwayCguAccepted,
                                 passwordsMatch,
                                 handleEmailBlur,
                                 civilite,
                                 setCivilite
                             }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    // const [error, setError] = useState(null);

    const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const onConnexionBtnCLick = () => {
        window.location.assign("http://localhost/login.php");
    }

    return (
        <form className="mt-2 space-y-4 w-full max-w-2xl flex flex-col sm:flex-row sm:space-y-0 sm:space-x-4"
              onSubmit={handleSubmit}>
            <div className="w-full sm:w-1/2 space-y-4">
                <div className="">
                    <label className="block text-sm font-medium">Civilité</label>
                    <div className="mt-1 flex space-x-4">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="civilite"
                                value="M"
                                checked={civilite === "M"}
                                onChange={() => setCivilite("M")}
                                required
                                className="h-4 w-4 text-primary border-gray-300 focus:ring-primary"
                            />
                            <span className="ml-2">Homme</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="civilite"
                                value="F"
                                checked={civilite === "F"}
                                onChange={() => setCivilite("F")}
                                required
                                className="h-4 w-4 text-primary border-gray-300 focus:ring-primary"
                            />
                            <span className="ml-2">Femme</span>
                        </label>
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-medium">Nom du gérant</label>
                    <input
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Prénom du gérant</label>
                    <input
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Email</label>
                    <input
                        type="email"
                        name="email"
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                        title="xxx@yy.zz"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        onBlur={handleEmailBlur}
                        required
                        className="mt-1 w-full block py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Date de naissance du gérant</label>
                    <input
                        type="date"
                        name="birthdate"
                        value={birthdate}
                        onChange={(e) => {
                            setBirthdate(e.target.value);
                        }}
                        required
                        className="mt-1 block py-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                </div>
                <div className="flex">
                    <div>
                        <label className="block text-sm font-medium">Nom de la société</label>
                        <input
                            type="text"
                            name="companyName"
                            value={companyName}
                            onChange={(e) => {
                                setCompanyName(e.target.value);
                            }}
                            required
                            className="mt-1 block w-10/12 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Siren</label>
                        <input
                            type="text"
                            name="siren"
                            value={siren}
                            onChange={(e) => {
                                setSiren(e.target.value);
                            }}
                            required
                            className="mt-1 block w-10/12 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        />
                    </div>
                </div>
                <div className="flex">
                    <div>
                        <label className="block text-sm font-medium text-center">Code promotionnel
                            (facultatif)</label>
                        <input
                            type="text"
                            name="promoCode"
                            value={promoCode}
                            onChange={(e) => {
                                setPromoCode(e.target.value);
                            }}
                            className="mt-1 block w-10/12 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-center">Code parrain (facultatif)</label>
                        <input
                            type="text"
                            name="sponsorCode"
                            value={sponsorCode}
                            onChange={(e) => {
                                setSponsorCode(e.target.value);
                            }}
                            className="mt-1 block w-10/12 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        />
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 space-y-4">
                <div className="relative">
                    <label className="block text-sm font-medium">Mot de passe</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm pr-10"
                    />
                    <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-6 flex items-center px-3 text-gray-500"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? <FaEyeSlash/> : <FaEye/>}
                    </button>
                </div>
                <div className="relative">
                    <label className="block text-sm font-medium">Confirmation du mot de passe</label>
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                        }}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm pr-10"
                    />
                    <button
                        type="button"
                        className="absolute inset-y-0 right-0 flex top-6 items-center px-3 text-gray-500"
                        onClick={toggleConfirmPasswordVisibility}
                    >
                        {showConfirmPassword ? <FaEyeSlash/> : <FaEye/>}
                    </button>
                </div>
                <div className="text-sm text-gray-400">
                    <p className={password.length >= 12 ? 'text-green-500' : ''}>
                        Le mot de passe doit faire au moins 12 caractères.
                    </p>
                    <p className={/[A-Z]/.test(password) ? 'text-green-500' : ''}>
                        Le mot de passe doit inclure au moins une majuscule.
                    </p>
                    <p className={/\d/.test(password) ? 'text-green-500' : ''}>
                        Le mot de passe doit inclure au moins un chiffre.
                    </p>
                    <p className={/[@$!%*?&_\-#^+=(){}[\]:;"'<>,./\\|~`]/.test(password) ? 'text-green-500' : ''}>
                        Le mot de passe doit inclure au moins un caractère spécial.
                    </p>
                    <p className={passwordsMatch() ? 'text-green-500' : ''}>
                        Les mots de passe sont identiques.
                    </p>
                </div>
                {error && <div className="text-red-500 text-sm">{error}</div>}
                <div>
                    <div>
                        <input
                            type="checkbox"
                            name="cgu"
                            id="cgu"
                            className="mr-2 cursor-pointer"
                            checked={isCguAccepted}
                            onChange={(e) => {
                                setIsCguAccepted(e.target.checked)
                            }}
                        />
                        <label htmlFor="cgu" className="text-sm">J'ai lu et j'accepte les <a
                            href={`${pretupClientSpace}/cgu`} target="_blank" className="text-secondary">Conditions
                            Générales d'Utilisation</a></label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            name="cgu_lemonway"
                            id="cgu_lemonway"
                            className="mr-2 cursor-pointer"
                            checked={isLemonwayCguAccepted}
                            onChange={(e) => {
                                setIsLemonwayCguAccepted(e.target.checked)
                            }}
                        />
                        <label htmlFor="cgu_lemonway" className="text-sm">Je certifie avoir pris connaissance et accepté
                            expressément les <a href={`${pretupClientSpace}/cgu_lemonway.pdf`}
                                                className="text-secondary"
                                                target="_blank">Conditions
                                Générales de l'établissement de paiement</a></label>
                    </div>
                </div>
                {/*Remplacée par l'alerte dans le toast */}
                {/*{inscriptionOk && <div*/}
                {/*    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4">{inscriptionOk}</div>}*/}
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-secondary hover:bg-secondaryLight text-white rounded-md"
                    // disabled={}
                >
                    Inscription
                </button>
            </div>
        </form>
    );
};

export default CompanyRegisterForm;