import React, {useEffect} from 'react'

import Banner from '../../components/Banner'
import Offer from '../../components/Offer'
import Projects from "./components/Projects";
import Stepper from "./components/Stepper";
import Process from "./components/Process";
import Services from "./components/Services";
import Boost from "./components/Boost";
import CardEmprunteur from "../../components/CardEmprunteur";
import LendAvantages from "../../components/LendAvantages";
import TrustPilotCarousel from "./components/TrustPilotCarousel";
import CTAInvest from "./components/CTAInvest";
import TrustPilotReview from "./components/TrustPilotReview";
import {useToasts} from "../../hooks/useToasts";
import {useLocation, useNavigate} from "react-router-dom";

const Home = () => {
    const userType = 'preteur';
    const {pushToast} = useToasts();
    const location = useLocation();
    const navigate = useNavigate();
    const backgroundClass = userType === 'emprunteur' ? 'bg-primary' : 'bg-secondary';
    const lightClass = userType === 'emprunteur' ? 'bg-primaryLight' : 'bg-secondaryLight';
    const darkClass = userType === 'emprunteur' ? 'bg-primaryDark' : 'bg-secondaryDark';

    // Si l'utilisateur a été redirigée ici après avoir
    // tapé une url invalide dans le navigateur. (voir NotFound.jsx)
    useEffect(() => {
        if (location.state?.fromNotFound) {
            pushToast({
                    content: "La page demandée n'existe pas",
                    type: "error",
                    position: "topCenter",
                    duration: 3
                }
            );

            // On "simule" une redirection vers cette même page
            // en nettoyant le state, cela evite l'apparition
            // de l'alerte quand l'utilisateur actualise la page.
            navigate(".", { replace: true, state: {} });
        }
    }, [location.state])


    // Injection du script du carousel de Trustpilot dans la page
    const isScriptOnPage = !!document.querySelector('script[src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"]');
    if (!isScriptOnPage) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        document.head.appendChild(script);
    }

    return (
        <div className='min-h-[1800px]'>
            <Banner userType={userType}/>
            <TrustPilotReview/>
            <Projects/>
            <Boost/>
            <Offer
                backgroundClass={backgroundClass}
                lightClass={lightClass}
                darkClass={darkClass}
            />
            <Stepper/>
            <CTAInvest/>
            <LendAvantages/>
            <Process/>
            <Services/>
            <TrustPilotCarousel/>
            <CardEmprunteur/>
        </div>
    )
}

export default Home