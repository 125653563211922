export const helpButtons = [
    {
        content: 'Consultez la FAQ',
        action: '/preter/faq-preteur'
    },
    {
        content: 'Contacter un conseiller',
        action: '/contact'
    }
];

export const returnMethodsBtn = {
    content: 'Retour aux méthodes',
    action: '/preter/methodes-de-cotation'
}

export const defaultCssUiButton = {
    fontSize: 'base',
    padding: {
        x: 4,
        y: 3
    },
    margin: {
        t: 0,
        r: 0,
        b: 0,
        l: 0
    },
}