import {Link} from "react-router-dom";

/**
 * Un composant qui affiche soit un bouton soit des liens en forme de boutons.
 * Le bouton possède des styles responsives préfait (voir la props btnType),
 * pour appliquer d'autres styles on peut passer une classe tailwind dans la props tailwindCssClass.
 *
 *
 * @param link {external: boolean, href: string} Si ce paramètre est renseigné le bouton
 * sera en fait un lien de type <a> si external est true ou <Link> si external est false
 *
 * @param text string Le contenu textuel du bouton
 *
 * @param tailwindClass string Les styles additionnels, le margin, padding
 * et font-size ne peuvent pas être renseignés ici.
 *
 * @param hover boolean Si true ajoute un effet de hover compatible avec le type du bouton.
 *
 * @param btnType 'secondary' | 'secondaryBorder' Les couleurs classiques du bouton
 *
 * @param handleClick function N'est pas utilisable pour les liens.
 */
// const Button = ({
//                     text,
//                     link = null,
//                     btnType,
//                     tailwinClass = "",
//                     hover = false,
//                     handleClick
//                 }) => {
//     let finalClass = "inline-block rounded-lg text-center ";
//
//     if (btnType === "secondary") {
//         finalClass += " px-3 py-2 md:px-4 md:py-3 lg:px-5 lg:py-4 bg-secondary text-white text-base md:text-lg lg:text-xl";
//         if (hover) {
//             finalClass += " transition border-2 border-transparent hover:bg-white hover:text-secondary hover:border-2 hover:border-secondary";
//         }
//     } else if (btnType === "secondaryBorder") {
//         finalClass += " px-3 py-2 md:px-4 md:py-3 lg:px-5 lg:py-4 bg-transparent border-secondary border-2 rounded-lg text-secondary text-base md:text-lg lg:text-xl";
//         if (hover) {
//             finalClass += " transition hover:bg-secondary hover:text-white";
//         }
//     }
//     // Implémenter le code pour le type primary.
//
//     finalClass += " " + tailwinClass;
//
//     if (link && link.external && link.href) {
//         return <a href={link.href} className={finalClass}>{text}</a>;
//     } else if (link && link.external === false && link.href) {
//         return <Link to={link.href} className={finalClass}>{text}</Link>
//     } else {
//         return handleClick ?
//             <button onClick={handleClick} className={finalClass}>{text}</button>
//             :
//             <button className={finalClass}>{text}</button>;
//     }
// }
//

/**
 * Un composant qui affiche soit un bouton soit des liens en forme de boutons.
 * Le bouton possède des styles responsives préfaits (voir la props btnType),
 * pour appliquer d'autres styles on peut passer une classe tailwind dans la props tailwindCssClass.
 *
 * @param link {external: boolean, href: string, target: string} Si ce paramètre est renseigné le bouton
 * sera en fait un lien de type <a> si external est true ou <Link> si external est false
 *
 * @param text string Le contenu textuel du bouton
 *
 * @param tailwindClass string Les styles additionnels, le margin, padding
 * et font-size ne peuvent pas être renseignés ici.
 *
 * @param hover boolean Si true ajoute un effet de hover compatible avec le type du bouton.
 *
 * @param btnType 'secondary' | 'secondaryBorder' | 'transparentSecondary' Les couleurs classiques du bouton
 *
 * @param handleClick function N'est pas utilisable pour les liens.
 *
 * @param icon React.ComponentType | null Une icône (sous forme de composant React) à afficher.
 *
 * @param iconPosition 'left' | 'right' | 'only' Position de l'icône : avant, après ou seule.
 *
 * @param size "xs" | "small" | "medium" | "large"
 */
const Button = ({
                    text,
                    link = null,
                    btnType,
                    tailwinClass = "",
                    hover = false,
                    handleClick,
                    icon: Icon = null,
                    iconPosition = "left",
                    size = "medium",
                }) => {
    let finalClass = "inline-block rounded-lg text-center";

    switch (size) {
        case "xs":
            finalClass += " px-2 sm:px-2 md:px-2 py-1 sm:py-1 md:py-1" +
                " text-sm md:text-sm lg:text-base";
            break;
        case "small":
            finalClass += " px-2 sm:px-2 md:px-3 py-1 sm:py-2 md:py-2" +
                " text-sm md:text-base lg:text-lg";
            break;
        case "medium":
            finalClass += " px-3 sm:px-3 md:px-4 lg:px-5 py-2 sm:py-3 md:py-3 lg:py-4" +
                " text-base md:text-lg lg:text-xl";
            break;
        case "large":
            finalClass += " px-4 sm:px-4 md:px-5 lg:px-6 py-3 sm:py-4 md:py-4 lg:py-5" +
                " text-base md:text-lg lg:text-xl";


    }

    if (btnType === "secondary") {
        finalClass += " bg-secondary text-white";
        if (hover) {
            finalClass += " transition border-2 border-transparent hover:bg-white hover:text-secondary hover:border-2 hover:border-secondary";
        }
    } else if (btnType === "secondaryBorder") {
        finalClass += " bg-transparent border-secondary border-2 rounded-lg text-secondary";
        if (hover) {
            finalClass += " transition hover:bg-secondary hover:text-white";
        }
    } else if (btnType === "transparentSecondary") {
        finalClass += " bg-transparent text-secondary";
        if (hover) {
            finalClass += " transition hover:bg-secondary hover:text-white border-2 border-transparent hover:border-secondary";
        }
    }
    // Implémenter le code pour le type primary.

    finalClass += " " + tailwinClass;

    const content = (
        <span className="flex items-center justify-center gap-2">
            {Icon && (iconPosition === "left" || iconPosition === "only") && Icon}
            {iconPosition !== "only" && <span>{text}</span>}
            {Icon && iconPosition === "right" && Icon}
        </span>
    );



    if (link && link.external && link.href) {
        if (!['_blank', '_self', '_parent', '_top'].includes(link.target)) {
            link.target = "_self";
        }

        return <a href={link.href} className={finalClass} target={link.target}>{content}</a>;
    } else if (link && link.external === false && link.href) {
        return <Link to={link.href} className={finalClass}>{content}</Link>;
    } else {
        return handleClick ?
            <button onClick={handleClick} className={finalClass}>{content}</button>
            :
            <button className={finalClass}>{content}</button>;
    }
}
export default Button;