import BorderWrapper from "./ui/wrappers/BorderWrapper";
import {FaCircleInfo} from "react-icons/fa6";
import {BiError} from "react-icons/bi";
import { motion } from "framer-motion";
import { FaRegCheckCircle } from "react-icons/fa";

const Toast = ({content, type, position}) => {
    let icon = null;
    let backgroundColor = "";
    let classPosition = "";
    let animations= {};

    switch (type) {
        case "info":
            icon = <FaCircleInfo size={20}/>;
            backgroundColor = "bg-amber-200";
            break;
        case "error":
            icon = <BiError size={20}/>;
            backgroundColor = "bg-red-200";
            break;
        case "success":
            icon = <FaRegCheckCircle size={20} />;
            backgroundColor = "bg-green-200";
            break;
        default:
            icon = <FaCircleInfo size={20}/>;
            backgroundColor = "bg-amber-200";
            break;
    }

    switch (position) {
        case "topCenter":
            classPosition = "top-10 left-1/2 transform -translate-x-1/2";
            animations = {
                initial: {opacity: 0, y: -100},
                animate: {opacity: 1, y: 0},
                exit: {opacity: 0, y: -100}
            };
            break;
        case "topRight":
            classPosition = "top-3 right-3";
            animations = {
                initial: {opacity: 0, x: 150},
                animate: {opacity: 1, x: 0},
                exit: {opacity: 0, x: 150}
            };
            break;
        default:
            classPosition = "top-10 left-1/2 transform -translate-x-1/2";
            animations = {
                initial: {opacity: 0, y: -100},
                animate: {opacity: 1, y: 0},
                exit: {opacity: 0, y: -100}
            };
    }

    return <motion.div
        initial={animations.initial}
        animate={animations.animate}
        exit={animations.exit}
    >
        <BorderWrapper tailwindClass={`fixed flex flex-col ${backgroundColor} ${classPosition} z-50`}
                       className="z-50">
            {/*<div className="px-3 border-b-2 border-primary">*/}
            {/*    <h3>Erreur: {title}</h3>*/}
            {/*</div>*/}
            <div className="flex flex-1 justify-center items-center px-3 py-2 text-center">
                {icon}
                <p className="ml-2 font-semibold text-lg">{content}</p>
            </div>
        </BorderWrapper>
    </motion.div>
}

export default Toast;