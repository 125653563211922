import {useNavigate} from 'react-router-dom';
const Buttons = ({title, buttons}) => {
    const navigate = useNavigate();
    const handleBtnClick = (action) => {
        navigate(action);
    }

    return (
        <div>
            <h3 className="base-h3 text-secondary">{title}</h3>
            <div className="flex gap-6">
                {buttons.map(button => (
                    <button className='btn btn--secondary' onClick={() => handleBtnClick(button.action)}>{button.content}</button>
                ))}
            </div>
        </div>
    )
}

export default Buttons;