import H1Base from "../../../components/ui/titles/H1Base";
import Pastille from "../../../components/ui/wrappers/Pastille";
import {formatDate} from "../../../functions/functions"
import {useState} from "react";

const HeroBlog = ({article, handleClick}) => {
    const [imgSrc, setImgSrc] = useState("");
    const [isErrorImg, setIsErrorImg] = useState(false);

    if (article.PhotosLocal &&
        article.PhotosLocal.length > 0 &&
        imgSrc !== `/images/articles/${article.ArticleId}/${article.PhotosLocal[0]}` &&
        !isErrorImg
    ) {
        setImgSrc(`/images/articles/${article.ArticleId}/${article.PhotosLocal[0]}`);
    }

    const handleErrorImg = () => {
        if (isErrorImg) {
            return;
        }

        setIsErrorImg(true);

        if (article.Photo &&
            article.Photo.length > 0) {
            setImgSrc(article.Photo[0]);
        }
        else {
            setImgSrc('');
        }
    }
    return <div
        className={"mx-auto mb-12 md:mb-16 lg:mb-20  flex w-full h-96 lg:w-11/12 hover:opacity-95 cursor-pointer"}
        onClick={() => handleClick(article.ArticleId)}
    >
        <div className={"pt-2 mr-8 flex-1 flex flex-col justify-between"}>
            {article.Pastilles && article.Pastilles.length > 0 &&
                <div className={"flex gap-x-3"}>
                    {article.Pastilles.map((text, index) => {
                        return <Pastille
                            className={"bg-secondary text-center text-white py-1 px-2"}
                            key={`fishermanFriends-${index}`}
                        >{text}</Pastille>
                    })
                    }
                </div>
            }
            <H1Base text={article.Titre} className={"text-center w-4/5"}/>
            <p className={"text-right"}>Le {formatDate(article.DateCreation.date)}</p>
        </div>
        <img
            className="flex-1 h-full w-full object-cover object-center"
            src={imgSrc}
            onError={handleErrorImg}
        />
    </div>
}

export default HeroBlog;