import {format} from "date-fns";
import {fr} from "date-fns/locale";

export const calculateProgress = (montantRecolte, montantRecherche) => {
    return (montantRecolte / montantRecherche) * 100;
};

export const getProgressBarColor = (progress) => {
    return progress >= 100 ? 'bg-green-500' : 'bg-secondary';
};

export const getRiskClassColor = (riskClass) => {
    if (riskClass.startsWith('A')) {
        return 'text-green-500';
    } else if (riskClass.startsWith('B')) {
        return 'text-yellow-500';
    } else if (riskClass.startsWith('C')) {
        return 'text-orange-500';
    } else if (riskClass.startsWith('D')) {
        return 'text-red-500';
    } else {
        return 'text-gray-500';
    }
};

/**
 * Génère un texte indiquant en combien de temps
 * un projet a été financé.
 * Si le projet n'est pas totalement financé, renvoie
 * une chaine vide.
 * @param duration string
 */
export const generateDurationText = (duration) => {
    return duration === "Non terminé" ? "" : ` en ${duration}`;
}

/**
 * Transforme une date de type 11/11/2020
 * en 11 novembre 2020.
 *
 * Si la date en paramètre n'est pas au bon format,
 * renvoie false;
 *
 * @param dateString
 * @returns {string}
 */
export const formatDateWithoutSlashes = (dateString) => {
    // Test si on a bien une string du genre "12/12/2021".
    const regex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;

    if (!regex.test(dateString)) {
        console.error("Mauvaise date : ", dateString);
        return false;
    }

    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);

    try {
        return format(date, 'd MMMM yyyy', {locale: fr});;
    } catch (e) {
        console.error("Date erronée :", date);
    }
}

export const generateDateFinancementTexte = (dateString) => {
    const formattedDate = formatDateWithoutSlashes(dateString);

    return formattedDate ? `, financé le ${formattedDate}` : "";
}