import Slider from "react-slick";
import H3Base from "../../../components/ui/titles/H3Base";
import ProjectCard from "./ProjectCard";
import React from "react";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md";
import Loader from "../../../components/Loader";
import ErrorWrapper from "../../../components/ui/wrappers/ErrorWrapper";

const NextArrow = ({onClick}) => (
    <button
        className="absolute top-1/2 right-[-35px] transform -translate-y-1/2 p-2 text-secondary"
        onClick={onClick}
    >
        <MdKeyboardArrowRight className="h-10 w-10"/>
    </button>
);

const PrevArrow = ({onClick}) => (
    <button
        className="absolute top-1/2 left-[-35px] transform -translate-y-1/2 p-2 text-secondary"
        onClick={onClick}
    >
        <MdKeyboardArrowLeft className="h-10 w-10"/>
    </button>
);
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: "unslick"
        }
    ]
};

const ProjectSlider = ({projects, error, loading, tailwindClass = ""}) => {
    let finalClass;
    // <H3Base text="Les projets en cours : " titleSize={3} />
    const titleComponent = <H3Base text="Les projets en cours : " titleSize={3} />;
    if (typeof tailwindClass !== "string") {
        tailwindClass = "";
    }
    finalClass = "hidden sm:block " + tailwindClass;

    if (loading) {
        return <div>
            {titleComponent}
            <Loader/>
        </div>
    }

    if (error) {
        return <div>
            {titleComponent}
            <ErrorWrapper error={error} />
        </div>
    }

    return <div className={finalClass}>
        <H3Base text="Les projets en cours : " titleSize={3} />
        <Slider {...settings}>
            {projects.map((project, index) => (
                <ProjectCard project={project} key={`project-slide-${index}`}
                             index={index}/>
            ))}
        </Slider>
    </div>

}

export default ProjectSlider;