import {tutorialDatas} from "../../constants/tutorialDatas";
import {useLocation, useNavigate} from "react-router-dom";

const Tutorial = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const index = parseInt(location.pathname.slice(-1));
    const nextIndex = index + 1;
    const prevIndex = index - 1;
    const currentTutorial = tutorialDatas[index - 1];


    console.log(index);
    return (
        <div>
            <h1 className="mb-16 text-4xl lg:text-[48px] font-semibold leading-none text-secondary text-center">La
                gestion de mon compte Pretup</h1>
            <div className="mb-16 flex justify-end">
                <button onClick={() => navigate(`/preter/tutoriels`)}
                        className="btn btn--secondary block text-sm"> Retour à la liste des tutoriels
                </button>
            </div>
            <div className="mb-16 mx-auto w-full lg:w-3/5 h-96">
                <iframe
                    loading="lazy"
                    className="w-full h-full"
                    src={currentTutorial.iframeSrc}
                    allowFullScreen
                ></iframe>
            </div>
            <div>
                <div className="mb-16 flex justify-between">
                    {index > 1 &&
                        <div className="w-[calc(50%-2rem)] flex flex-col items-start justify-between md:w-auto">
                            <h5 className="mb-6 text-base  text-secondary font-semibold md:text-lg">Tutoriel précédent : </h5>
                            <p className="mb-6 text-sm md:text-base">{tutorialDatas[index - 2].title}</p>
                            <button onClick={() => navigate(`/preter/tutoriel/${prevIndex}`)}
                                    className="btn btn--secondary text-sm">Accéder au tutoriel
                            </button>
                        </div>
                    }
                    {index < 6 &&
                        <div className="w-[calc(50%-2rem)] flex flex-col items-end justify-between text-right md:w-auto">
                            <h5 className="mb-6 text-base text-secondary font-semibold md:text-lg">Tutoriel suivant : </h5>
                            <p className="mb-6 text-sm md:text-base">{tutorialDatas[index].title}</p>
                            <button onClick={() => navigate(`/preter/tutoriel/${nextIndex}`)}
                                    className="btn btn--secondary text-sm">Accéder au tutoriel
                            </button>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default Tutorial;