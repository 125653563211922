import H2Base from "../../../components/ui/titles/H2Base";
import CardRelation from "./CardRelation";
import {generateContactLink} from "../functions/genericTemplateFunctions";

const AProposRelations = ({
                              title,
                              titleClass,
                              relations,
                                className = ""
                          }) => {
    return <div className={className}>
        <H2Base text={title} tailwindClass={titleClass}/>
        <div className={"grid gap-y-3 justify-items-center grid-cols-1 md:justify-items-start md:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4"}>
            {relations.map((relation, index) => <CardRelation
                relation={relation}
                key={`card-^${relation.job}-${index}`}
                linkElts={Object.keys(relation).map((key) => {
                    if (!['mail', "tel", "linkedin"].includes(key)) return null;
                    const value = relation[key];
                    return generateContactLink(key,  value, index, 20);
                })}
            />)}
        </div>
    </div>
}

export default AProposRelations;