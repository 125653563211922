export const tutorialDatas = [
    {
        title: "1. A quoi sert mon tableau de bord",
        description: "Focus sur le fonctionnement du tableau de bord de votre espace personnel, quelles données y sont présentes, comment vous en servir et piloter votre activité de prêteur.",
        iframeSrc: "https://www.youtube.com/embed/WdvNRAWNS24"
    },
    {
        title: "2. Outil de prêt automatique Boost PretUp",
        description: "Ce tutoriel explique le fonctionnement du module de prêt automatique Boost PretUp : mode simple, en mode avancé (paramétrage des critères de taux, durée).",
        iframeSrc: "https://www.youtube.com/embed/LNfQ_NiFBuA"
    },
    {
        title: "3. Le suivi de mes impayés",
        description: "Dans le cas où l’entreprise ne règle pas à temps ses échéances, découvre comment PretUp vous informe, puis comment PretUp effectue le recouvrement amiable et judiciaire des échéances.",
        iframeSrc: "https://www.youtube.com/embed/LN_i4fQAd8o"
    },
    {
        title: "4. L'intérêt et les atouts de mes informations personnelles",
        description: "Voyons ensemble comment remplir mes informations personnelles mais également les sous-rubriques de cet onglet, tel que le changement de mot de passe, les pièces justificatives, la saisie de l’adresse, valider son compte, et le parrainage.",
        iframeSrc: "https://www.youtube.com/embed/sjnjMNIZwDg"
    },
    {
        title: "5. Mettre à profit mes statistics",
        description: "Qui suis-je ? Cet onglet vous donne accès à l’ensemble des projets auxquels vous avez participés, et vous permet d’en déduire votre profil d’investisseur.",
        iframeSrc: "https://www.youtube.com/embed/EX6eb62jjQU"
    },
    {
        title: "6. Quelle est la fiscalité du crowdlending",
        description: "Focus sur les spécificités de la fiscalité sur le crowdfunding, on vous dit tout ! Nous avons mis en place la retenue à la source (Flat Tax), les documents IFU (Imprimé Fiscal Unique).",
        iframeSrc: "https://www.youtube.com/embed/kt5uuBRVPis"
    },
]