import Cards from "../../components/Cards";
import React from "react";
import Buttons from "../../components/Buttons";
import {helpButtons} from "../../constants/buttons";

const cards = [
    {
        title: "1. A quoi sert mon tableau de bord",
        image: "/images/tutos/tuto_1.jpg",
        content: "🕒 7 min 06 s",
        link: '/preter/tutoriel/1',
    },
    {
        title: "2. Outil de prêt automatique Boost PretUp",
        image: "/images/tutos/tuto_2.jpg",
        content: "🕒 5 min 18 s",
        link: '/preter/tutoriel/2'
    },
    {
        title: "3. Le suivi de mes impayés",
        image: "/images/tutos/tuto_3.jpg",
        content: "🕒 3 min 25 s",
        link: '/preter/tutoriel/3'
    },
    {
        title: "4. L'intérêt et les atouts de mes informations personnelles",
        image: "/images/tutos/tuto_4.jpg",
        content: "🕒 3 min 51 s",
        link: '/preter/tutoriel/4'
    },
    {
        title: "5. Mettre à profit mes statistics",
        image: "/images/tutos/tuto_5.jpg",
        content: "🕒 2 min 53 s",
        link: '/preter/tutoriel/5'
    },
    {
        title: "6. Quelle est la fiscalité du crowdlending",
        image: "/images/tutos/tuto_6.jpg",
        content: "🕒 4 min 00 s",
        link: '/preter/tutoriel/6'
    },

]


const Tutorials = () => {
    return (
        <div className="mb-16">
            <h1 className='mb-10 text-3xl lg:text-4xl font-semibold leading-none text-secondary'>Bienvenue dans les
                tutoriels Pretup</h1>
            <div className="mb-12">
                <Cards
                    cards={cards}
                    className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-16 gap-y-8"
                    cardType="cardImage"
                />
            </div>
            <Buttons buttons={helpButtons} title="Besoin d'aide supplémentaire ?"/>
        </div>
    )
}

export default Tutorials;