import {FaPlus} from "react-icons/fa";
import {FaMinus} from "react-icons/fa";
import Loader from "../../../components/Loader";

const Simulator = ({datas, handleBtnsClick, amount, isLoading, error}) => {
    const frenchDate = Intl.DateTimeFormat('fr-FR').format(new Date());

    return (
        <div className="mb-16">
            <h2 className="base-h2-white">Simulateur au {frenchDate}</h2>
            <p className="mb-12 text-center">Simulation d'un prêteur ayant prêté 1200 € par projet depuis le lancement
                de PretUp </p>
            <div className="w-1/2 mx-auto text-center">
                {isLoading ?
                    <Loader/>
                    :
                    error ?
                        <p className="font-bold text-secondaryDark text-xl"><span className="text-3xl">⚠ </span> {error}  <span className="text-3xl"> ⚠</span></p>
                        :
                        <>
                            <div className="mb-10 flex w-fit gap-10 mx-auto">
                                <div className="text-center">
                                        <span
                                            className="block text-secondary font-bold text-2xl">{datas.projetsFinances}</span>
                                    <p className="text-lg">Projets financés</p>
                                </div>
                                <div className="text-center">
                                        <span
                                            className="block text-secondary font-bold text-2xl">{datas.tauxMoyen}%</span>
                                    <p className="text-lg">Taux moyen constaté</p>
                                </div>
                            </div>
                            <div className="md:flex ">
                                <div className="md:w-1/5 flex md:flex-col items-center justify-center">
                                    <FaPlus onClick={() => handleBtnsClick('+')}
                                            className="mb-8 ml-4 cursor-pointer text-secondaryLight order-3 md:ml-0 md:order-1" size={30}/>
                                    <span className="mb-8 ml-4 text-2xl text-secondary font-bold order-2 md:ml-0 md:order-2">{amount} €</span>
                                    <FaMinus onClick={() => handleBtnsClick('-')}
                                             className="mb-8 cursor-pointer text-secondaryLight order-1 md:mb-0 md:order-3" size={30}/>
                                </div>
                                <div className="md:w-4/5 md:text-lg">
                                    <table className="text-left border-2">
                                        <tbody>
                                        <tr>
                                            <th className="p-2 border-2">Montant total prêté :</th>
                                            <td className="p-2 border-2">{datas.MontantTotal}€</td>
                                        </tr>
                                        <tr>
                                            <th className="p-2 border-2">Capital Restant Dû :</th>
                                            <td className="p-2 border-2">{datas.CRD}€</td>
                                        </tr>
                                        <tr>
                                            <th className="p-2 border-2">Intérêts reçus :</th>
                                            <td className="p-2 border-2">{datas.interetsRecus}€</td>
                                        </tr>
                                        <tr>
                                            <th className="p-2 border-2">Intérêts à percevoir :</th>
                                            <td className="p-2 border-2">{datas.interetsAPercevoir}€</td>
                                        </tr>
                                        <tr>
                                            <th className="p-2 border-2">Montant total des intérêts (hors
                                                fiscalité*) :
                                            </th>
                                            <td className="p-2 border-2">{datas.MontantTotalInterets}€</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="text-sm text-left">
                                        <p>* Les performances présentées dans le tableau peuvent varier selon l’évolution du taux de retard constaté sur les prêts 'Entreprises'</p>
                                    </div>
                                </div>
                            </div>
                        </>

                }
            </div>
        </div>
    )
}
export default Simulator;