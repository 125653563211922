import React  from 'react';
import Carousel from './Carousel';
import Infos from './Infos';

const Banner = ({ userType }) => {
    const isEmprunteur = userType === 'emprunteur';

    return (
        <section className='h-full mb-12 md:mb-14'>
            <div className='mb-8 flex flex-col lg:flex-row'>
                <div className='lg:ml-8 xl:ml-[135px] flex flex-col items-center lg:items-start text-center lg:text-left flex-1 px-4 lg:px-0'>
                    {isEmprunteur ?
                        <h1 className={`text-4xl lg:text-5xl font-semibold leading-none mb-6 ${isEmprunteur ? 'text-primary' : 'text-secondary'}`}>
                            Accédez à une solution de Financement simple
                        </h1>
                        :
                        <h1 className={`text-4xl lg:text-5xl font-semibold leading-none mb-6 ${isEmprunteur ? 'text-primary' : 'text-secondary'}`}>
                            La Plateforme d'investissement participatif dans les entreprises pour tous
                        </h1>

                    }

                    <p className='max-w-[480px] mb-8'>
                        Spécialiste du financement participatif depuis 2015, PretUp propose un investissement dans les PME et permet à chaque investisseur de prendre part au développement économique en finançant les entreprises.
                    </p>
                </div>
                <div className="flex-1 lg:max-w-[50%] hidden lg:block">
                    <Carousel />
                </div>
            </div>
            <Infos userType={userType} />
        </section>
    );
}

export default Banner;