import {TbLoader2} from "react-icons/tb";
import React from "react";

const Loader = () => {
    return (
        <div className="flex items-center justify-center min-h-96">
            <div className="flex flex-col items-center justify-center">
                <TbLoader2 size={100} className="mb-3 animate-spin"/>
                <p className="text-2xl font-bold">Chargement...</p>
            </div>
        </div>
    );
}
export default Loader;