import React, {useState} from 'react';
import axios from 'axios';
import {checkMail} from "../../../services/api";
import { AiOutlineReload } from "react-icons/ai";
import {validateEmail} from "../../../functions/functions";

const Form = () => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        siret: '',
        amount: null,
        turnover: null,
        companyName: '',
        message: '',
        profits: '',
    });

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertErrorVisible, setAlertErrorVisible] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState("");
    const [isMailValid, setIsMailValid] = useState(false);
    const [isMailChecking, setIsMailChecking] = useState(false);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const handleChange = (e) => {
        if (e.target.type === 'file') {
            setFormData({
                ...formData,
                report: e.target.files[0],
            });
        } else {
            const {id, value} = e.target;
            setFormData({
                ...formData,
                [id]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isMailValid) {
            if (!alertErrorVisible) {
                setAlertErrorMessage("Cet email est déjà utilisé");
                setAlertErrorVisible(true);
            }
            return;
        }

        const datas = {...formData}

        if (datas.report) {
            const type = datas.report.type;

            if (type !== "application/pdf" && type.slice(0, 4) !== "image") {
                setAlertErrorMessage("La plaquette comptable doit être une image ou un fichier pdf.");
                setAlertErrorVisible(true);
                setTimeout(() => {
                    setAlertErrorVisible(false);
                }, 10000);
                return;
            } else {
                if (alertErrorMessage === "La plaquette comptable doit être une image ou un fichier pdf.") {
                    setAlertErrorVisible(false);
                    setAlertErrorMessage("");
                }
            }
        }

        datas.amount = parseInt(datas.amount, 10);
        if (datas.turnover) {
            datas.turnover = parseInt(datas.turnover, 10);
        } else {
            delete datas.turnover;
        }

        if (datas.profits) {
            datas.profits = parseInt(datas.profits, 10);
        } else {
            delete datas.profits;
        }

        if (!datas.siret) {
            delete datas.siret;
        }

        try {
            const response = await axios.post(
                `${apiBaseUrl}/register/borrower`,
                datas,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
            }, 10000);
            e.target.reset();

        } catch (error) {
            const message = error?.response?.data?.message;

            if (message) {
                if (message.slice(0, 4).toLowerCase() === "mail") {
                    setAlertErrorMessage("Cet email est déjà utilisé");
                } else {
                    setAlertErrorMessage(message);
                }
            } else {
                setAlertErrorMessage("Impossible d'envoyer les données au serveur.");
            }

            setAlertErrorVisible(true);
            setTimeout(() => {
                setAlertErrorVisible(false);
            }, 10000);
        }
    };

    const handleEmailBlur = async (e) => {
        const mail = e.target.value;

        if (!validateEmail(mail)) {
            return;
        }

        setIsMailChecking(true);
        const isValid = await checkMail(mail);
        setIsMailChecking(false);

        if (isValid) {
            if (alertErrorMessage === "Cet email est déjà utilisé") {
                setAlertErrorMessage("");
                setAlertErrorVisible(false);
            }
            setIsMailValid(true);
        } else {
            setAlertErrorMessage("Cet email est déjà utilisé");
            setAlertErrorVisible(true);
            setIsMailValid(false);
        }


    };
    return (
        <div id="fundingForm"
             className="mx-auto mb-12 py-6 max-w-2xl px-4 bg-gray-100 rounded-lg shadow-md sm:px-6 md:mb14 lg:max-w-7xl lg:px-8 xl:mb-24">
            <h2 className="text-4xl font-bold text-white bg-primary py-3 rounded-lg text-center">Obtenez un
                financement</h2>
            <div className="text-center mt-4 text-gray-600 text-sm">
                <p>Merci de compléter ce questionnaire. Notre équipe vous recontacte sous 48h heures.</p>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="mt-6 grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                        <label htmlFor="firstname"
                               className="block mb-2 text-sm font-medium text-gray-900">Prénom du gérant *</label>
                        <input type="text" id="firstname"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Prénom" required onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="lastname" className="block mb-2 text-sm font-medium text-gray-900">Nom du
                            gérant *</label>
                        <input type="text" id="lastname"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Nom" required onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email *</label>
                        <input type="email" id="email"
                               pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                               title="xxx@yy.zz"
                               onBlur={handleEmailBlur}
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Email" required onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="phone"
                               className="block mb-2 text-sm font-medium text-gray-900">Téléphone *</label>
                        <input type="tel" id="phone"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Téléphone"
                               required
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="companyName"
                               className="block mb-2 text-sm font-medium text-gray-900">Nom de l'entreprise *</label>
                        <input type="text" id="companyName" name="companyName"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Nome de l'entreprise" required
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="siret" className="block mb-2 text-sm font-medium text-gray-900">Siret</label>
                        <input type="text"
                               id="siret"
                               name="siret"
                               pattern="\d{14}"
                               title="Le numéro de SIRET doit comporter exactement 14 chiffres"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="N° Siret"
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="turnover"
                               className="block mb-2 text-sm font-medium text-gray-900">Chiffre d'affaires (€)</label>
                        <input type="number" id="turnover"
                               name="turnover"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Chiffre d'affaires"
                               min={0}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="profits"
                               className="block mb-2 text-sm font-medium text-gray-900">Résultat (€)</label>
                        <input type="number" id="profits"
                               name="profits"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Résultat"
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">Montant demandé
                            (€) *</label>
                        <input type="number" id="amount"
                               name="amount"
                               min={1}
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                               placeholder="Montant demandé" required onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="accountingBrochure" className="block mb-2 text-sm font-medium text-gray-900">Dernière
                            plaquette comptable</label>
                        <input type="file" id="accountingBrochure" name="accountingBrochure"
                               accept="image/*, .pdf"
                               className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5 bg-white"
                               placeholder="Site Internet" onChange={handleChange}/>
                    </div>
                </div>
                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Objet du
                    financement *</label>
                <textarea id="message" rows="4"
                          name="message"
                          className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-primary focus:ring-primaryDark focus:border-primaryDark"
                          required
                          placeholder="Votre message ici..." onChange={handleChange}></textarea>
                {alertVisible && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4"
                         role="alert">
                        <strong className="font-bold">Votre formulaire a été envoyé avec succès.</strong>
                        <span className="block sm:inline"> Nos équipes prendrons contact avec vous après analyse de votre dossier.</span>
                    </div>
                )}
                {alertErrorVisible && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
                         role="alert">
                        <strong className="font-bold">Erreur :</strong>
                        <span className="block sm:inline"> {alertErrorMessage}</span>
                    </div>
                )}
                <div className="mt-6 flex items-center">
                    <button type="submit"
                            disabled={isMailChecking}
                            className={isMailChecking ?
                                "text-white bg-primaryLight hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center cursor-not-allowed"
                                :
                                "text-white bg-primaryLight hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                            }
                    >
                        Faisons connaissance
                    </button>
                    {isMailChecking &&
                        <div className="flex items-center text-sm">
                            <AiOutlineReload className="ml-2 animate-spin" />
                            <span className="ml-1">Email en cours de vérification...</span>
                        </div>
                    }
                </div>
            </form>
        </div>
    );
}

export default Form;