import React, {useEffect} from "react";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import CardService from "./CardService";


const Services = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({triggerOnce: true, threshold: 0.1});

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <div className="mb-12 md:mb14 xl:mb-24">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <motion.h2
                    className="text-4xl font-bold text-white bg-secondary py-3 rounded-lg text-center shadow-lg"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.8}}
                >
                    Un service optimisé
                </motion.h2>
                <div className="mt-4 mb-8 text-center text-gray-600">
                    <p>Notre engagement est entier pour vous proposer des placements sérieux et qui permettent le
                        financement direct d’entreprises qui présentent des perspectives tangibles de croissance.</p>
                </div>
                <motion.div
                    className="grid grid-cols-1 gap-10 lg:gap-5 lg:grid-cols-3"
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    variants={{visible: {transition: {staggerChildren: 0.2}}}}
                >
                    <CardService
                        classIcon="FaLightbulb"
                        index={1}
                        title="Expertise"
                        descriptions={[
                            "Nous sommes des experts du financement participatif (crowdlending) aux entreprises en France depuis 2015.",
                            "Nous avons accompagné plus de 1000 entreprises sur nos deux plateformes PretUp et Unilend."
                        ]}
                    />
                    <CardService
                        classIcon="FaChartPie"
                        index={2}
                        title="Diversification, intervention sur tous les secteurs d’activités"
                        descriptions={[
                            "Des entreprises françaises de tous secteurs d’activités sont présentées toutes les semaines:",
                            "commerces, industries, entreprises de services, agriculture, immobilier, transition écologique..."
                        ]}
                    />
                    <CardService
                        classIcon="FaClipboardCheck"
                        index={3}
                        title="Sélectivité et exigence de nos critères"
                        descriptions={[
                            "Toutes les entreprises présentées sur PretUp ont été rigoureusement séléctionnées.",
                            "Nous présentons soit des sociétés de plus de 3 années d’existence ayant un modèle économique éprouvé,",
                            "soit des sociétés franchisées en création accompagnées par une banque avec un prévisionnel supérieur à 100K€,",
                            "soit des professionnels de l’immobilier pour financer des opérations immobilières."
                        ]}
                    />
                </motion.div>
            </div>
        </div>
    );
};

export default Services;