import {useNavigate} from "react-router-dom";

/**
 * Carte qui comprend par défaut une bordure, des arrondis aux angles
 * et un box shadow.
 *
 * @param className La classe de la div qui englobe la card
 * @param title
 * @param titleClass
 * @param content
 * @param contentClass
 * @param bgColor
 * @param textColor
 * @param link
 * @param subtitle
 * @param subtitleClass
 * @returns {JSX.Element}
 * @constructor
 */
const Card = ({
                  className = "",
                  title,
                  titleClass = "",
                  subtitle = "",
                  subtitleClass = "",
                  content,
                  contentClass = "",
                  textColor = "",
                  link,

              }) => {
    const navigate = useNavigate();
    let finalClass = "";
    let finalTitleClass = `mb-2 text-2xl font-bold tracking-tight rounded-lg py-1 p-3`;
    let finalContentClass = "font-normal ";

    if (link) {
        finalClass = `block p-6 border border-gray-200 rounded-lg shadow cursor-pointer hover:brightness-125 ${className}`
    } else {
        finalClass = `block p-6 border border-gray-200 rounded-lg shadow  ${className}`
    }

    if (typeof textColor === "string") {
        finalClass += ` ${textColor} `;
    }

    if (typeof contentClass === "string") {
        finalContentClass += contentClass;
    }

    if (typeof className === "string") {
        finalClass += ` ${className}`;
    }


    if (typeof subtitleClass !== "string") {
        subtitleClass = "";
    }

    if (typeof titleClass === "string") {
        finalTitleClass += titleClass;
    }


    const handleClick = () => {
        if (link) {
            navigate(link);
        }
    }

    return (
        <div
            className={finalClass}
            onClick={handleClick}
        >
            {title &&
                <h3 className={finalTitleClass}>{title}</h3>
            }
            {subtitle &&
                <h4 className={subtitleClass}>{subtitle}</h4>
            }
            <p className={finalContentClass}>{content}</p>
        </div>
    );
}

export default Card;