import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useQuery} from "@tanstack/react-query";
import {getProjects} from "../services/newApi";
import {generateDateFinancementTexte} from "../pages/Home/functions/projectCardFunctions";
import Loader from "./Loader";
import ErrorWrapper from "./ui/wrappers/ErrorWrapper";

import {useNavigate} from "react-router-dom";

const Carousel = () => {
    const {
        isPending,
        data: projects,
        error,
        isError
    } = useQuery({
        queryKey: ["project-finished-10"],
        queryFn: async () => getProjects("finished", 10),
    });
    const navigate = useNavigate();
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    if (isPending) {
        return (<Loader />)
    }

    if (isError) {
        return <ErrorWrapper error={error.message} />
    }
    return (
        <div className="w-full h-full">
            <Slider {...settings} className="overflow-hidden">
                {projects && projects.map((project, index) => (
                    <div
                        key={index}
                        className="relative h-80 cursor-pointer hover:opacity-85"
                        onClick={() => {navigate("/inscription/preteur")}}
                    >
                        <img
                            className="h-full w-full object-cover rounded-lg"
                            src={`/images/projets/${project.ProjetId}.jpg`}
                            onError={(e) => e.target.src = project.Photo}
                            alt={`Slide ${index}`}
                        />
                        <div className="absolute inset-0 bg-green-600 bg-opacity-10">
                            <div className="flex justify-between p-2">
                                <div className="bg-white text-secondary font-semibold px-2 py-1 rounded">
                                  <span className="uppercase">{project.NomProjet}</span>
                                    {generateDateFinancementTexte(project.FinCollecte)}
                                </div>
                                <div className="bg-white text-green-700 font-bold px-2 py-1 rounded">
                                    Collecte Réussie
                                </div>
                            </div>
                        </div>
                        <span className={"absolute bottom-7 left-1/2 -translate-x-1/2 bg-secondary text-white rounded-lg text-center px-3 py-2 text-lg"}>
Voir nos opportunités
                        </span>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Carousel;