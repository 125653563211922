import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import InfosProjects from './InfosProjects';
import Button from "../../../components/ui/buttons/Button";
import {fetchProjectsHomeCarousel} from "../../../services/newApi"
import ProjectsView from "../../../components/ProjectsView";
import {useQuery} from "@tanstack/react-query";
import ProjectSlider from "./ProjectSlider";
import useWindowSize from "../../../hooks/useWindowSize";

const Projects = () => {
    let futureProjects = [];
    let projects = [];
    const {
        isPending: isLoading,
        data: allProjects,
        error,
        isError
    } = useQuery({
        queryKey: ["project-homepage"],
        queryFn: fetchProjectsHomeCarousel,
    });
    const windowSize = useWindowSize();


    if (allProjects && allProjects.length > 0) {
        const projectsFromApi = allProjects.reduce((acc, cur) => {
            if (cur.etat === "avenir") acc.futureProjects.push(cur);
            else acc.projects.push(cur);

            return acc;
        }, {futureProjects: [], projects: []});

        projects = projectsFromApi.projects;
        futureProjects = projectsFromApi.futureProjects;
    }


    return (
        <div className="mb-12 md:mb14 xl:mb-24">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-4xl font-bold text-white bg-secondary py-3 rounded-lg text-center mb-10"
                    id="currentProjects">Nos opportunités d’investissement</h2>
                <InfosProjects/>
                {futureProjects && futureProjects.length > 0 &&
                    <ProjectsView
                        projects={futureProjects}
                        error={isError ? error?.message : ""}
                        loading={isLoading}
                        title="Les projets à venir : "
                        titleSize={3}
                        tailwindClass="mb-12 flex flex-col justify-center items-center sm:block"
                    />
                }
                {windowSize.width > 640 ?
                    <ProjectSlider projects={projects} loading={isLoading} error={isError ? error.message : ""}
                                   tailwindClass="mb-12"/>
                    :
                    <ProjectsView
                        projects={projects.slice(0, 4)}
                        error={isError ? error?.message : ""}
                        loading={isLoading}
                        title="Les projets en cours : "
                        titleSize={3}
                        tailwindClass="mb-12 flex flex-col justify-center items-center"
                    />
                }

                <div className="w-full flex flex-col items-center text-lg">
                    <Button
                        text="Tous les projets"
                        link={{external: false, href: "/projets"}}
                        btnType="secondary"
                        tailwinClass="mx-auto mb-20"
                    />
                    <p className={"mb-8 text-2xl"}>Nous soutenons des entrepreneurs engagés</p>
                    <p className={"mb-2 text-5xl text-secondary"}>+4600 emplois</p>
                    <p className={"text-2xl text-secondary"}>crées ou préservés grâce à PretUp depuis 2015</p>
                </div>
            </div>
        </div>
    );
};

export default Projects;
