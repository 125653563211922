import BorderWrapper from "../../../components/ui/wrappers/BorderWrapper";

const CardRelation = ({relation, linkElts}) => {
    return <BorderWrapper  tailwindClass={"p-3 max-w-64"}>
        <img className={"mb-4 object-cover w-full h-72"} src={relation.image} alt={"A wonderful worker"} />
        <div className={"mb-5"}>
            <h3 className={"mb-2 text-secondary text-xl font-semibold"}>{relation.name}</h3>
            <p className={"font-semibold text-lg"}>{relation.job}</p>
        </div>
        <div className={"flex gap-x-5"}>
            {linkElts &&
                linkElts.map((elt, index) => elt)
            }
        </div>
    </BorderWrapper>
}

export default CardRelation;