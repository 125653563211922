import Card from './Card';
import CardImage from "./CardImage";


const Cards = ({cards, className, cardType}) => {

    return (
        <div className={className} >
            {cardType === "card" && cards.map((card, index) => (
                <Card
                    className={`${card.bgColor}`}
                    key={`card-${index}`}
                    title={card.title}
                    content={card.content}
                    textColor={card.textColor}
                    link={card.link}
                />
            ))}
            {cardType === "cardImage" && cards.map((card, index) => (
                <CardImage
                    key={`cardImage-${index}`}
                    image={card.image}
                    title={card.title}
                    content={card.content}
                    link={card.link}
                />
            ))
            }
        </div>
    );
}

export default Cards;
