import React from "react";
import Cards from "./Cards"

const LendAvantages = () => {
    const cards = [
        {
            title: 'Accessibilité',
            content: 'Des placements accessibles à partir de 20€.',
            bgColor: 'bg-secondary',
            textColor: 'text-white'
        },
        {
            title: 'Performance',
            content: 'Une rémunération brute annuelle ecomprise entre 7% et 11%.',
        },
        {
            title: 'Gratuité',
            content: "Vous ne payez rien: aucun frais d'entrée, de sortie ou de gestion.",
        },
        {
            title: 'Digital',
            content: 'Inscription en quelques clics, interface de gestion 100% en ligne.',
            bgColor: 'bg-secondary',
            textColor: 'text-white'
        }
    ];
    return (
        <div className="mx-auto mb-12 max-w-2xl text-center px-4 sm:px-6 md:mb14 lg:max-w-7xl lg:px-8 xl:mb-24">
            <div className="mb-6 mx-auto  lg:px-8">
                <h2 className={`text-4xl font-bold text-white py-3 rounded-lg text-center bg-secondary`}>
                    Trouvez auprès de chaque entreprise une nouvelle occasion d'épargner
                </h2>

                <div className="text-center mt-4 text-gray-600">
                    <p>Une fois votre compte créé, vous accédez aux détails de chaque opération. Dès 20€ vous commencez
                        à
                        épargner en prêtant directement aux entreprises de votre choix.</p>
                </div>
            </div>
            <div className="w-full lg:w-11/12 mx-auto">
                <Cards
                    cards={cards}
                    className="grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-8"
                    cardType="card"
                />
            </div>
        </div>
    )
}

export default LendAvantages;