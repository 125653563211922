import H3Base from "../../../components/ui/titles/H3Base";
import {useEffect, useState} from "react";
import Button from "../../../components/ui/buttons/Button";
import H2Base from "../../../components/ui/titles/H2Base";
import {useNavigate} from "react-router-dom";

const LandingForm = ({className}) => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
    });
    const handleChange = (e) => {
        const {id, value} = e.target;

        setFormData({
            ...formData,
            [id]: value
        });

    };
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        navigate("/inscription/preteur", { state: { form: { firstname: formData.firstname, lastname: formData.lastname, email: formData.email }} });
    }

    return (<>
        <H2Base text={"Inscription gratuite et sans engagements"}/>
        <form onSubmit={handleSubmit} className={className}>
        <div className="mt-6 flex flex-col  max-w-96">
            <div className="mb-4">
                <label htmlFor="firstname"
                       className="block mb-2">Prénom</label>
                <input type="text" id="firstname"
                       value={formData.firstname}
                       className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                       onChange={handleChange} required/>
            </div>
            <div className="mb-4">
                <label htmlFor="lastname"
                       className="block mb-2">Nom</label>
                <input type="text" id="lastname"
                       value={formData.lastname}
                       className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                       onChange={handleChange} required/>
            </div>
            <div className="mb-4">
                <label htmlFor="email"
                       className="block mb-2">Email</label>
                <input type="email" id="email"
                       value={formData.email}
                       className="border border-primary text-black text-sm rounded-lg focus:ring-primaryDark focus:border-primaryDark block w-full p-2.5"
                       onChange={handleChange} required/>
            </div>
            <Button tailwinClass={"md:self-start"} text={"Je m'inscris"} size={'small'} btnType={"secondary"} hover={true}
                    handleClick={handleSubmit}/>
        </div>
    </form></>)
}

export default LandingForm;