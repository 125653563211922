import React from 'react';

const Offer = ({ backgroundClass }) => {
    return (
        <div className="mb-12 md:mb14 xl:mb-24">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-none">
                    <h2 className={`text-4xl font-bold text-white ${backgroundClass} py-3 rounded-lg text-center`}>L’offre PretUp : un investissement simple d’accès</h2>
                    <div className="text-center mt-4 text-gray-600">
                        <p>Nous sommes un point de rencontre entre une communauté de financeurs et des entreprises
                            de croissance avec un besoin de financement. <br/>
                            En tant que Prestataire de services de financement participatif (PSFP), nous offrons aux particuliers,<br/>
                            une opportunité de diversification de leurs placements, complémentaire aux leviers classiques d’épargne et d’investissement.
                        </p>
                    </div>

                    <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        <div className="group relative">
                            <div className="bg-white rounded-lg p-6">
                                <h3 className="text-lg font-semibold text-gray-900">Immobilier</h3>
                                <img src="https://cdn.pixabay.com/photo/2016/11/29/03/53/house-1867187_1280.jpg" alt="Immobilier" className="mt-4 rounded-lg"/>
                                <p className="mt-2 text-lg text-gray-500">
                                    <span className="font-bold">Durée investissement :</span> 6 à 18 mois <br/>
                                    <span className="font-bold">Taux d’intérêt :</span> 9 à 11 % <br/>
                                    <span className="font-bold">Distribution mensuelle des intérêts :</span> Remboursement
                                    du capital In Fine
                                </p>
                            </div>
                        </div>
                        <div className="group relative">
                            <div className="bg-white rounded-lg p-6">
                                <h3 className="text-lg font-semibold text-gray-900">PME</h3>
                                <img src="https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090_1280.jpg" alt="PME" className="mt-4 rounded-lg"/>
                                <p className="mt-2 text-lg text-gray-500">
                                    <span className="font-bold">Durée investissement :</span> 12 à 60 mois <br/>
                                    <span className="font-bold">Taux d’intérêt :</span> 8 à 10 % <br/>
                                    <span className="font-bold">Distribution mensuelle des intérêts :</span> Crédit
                                    amortissable
                                </p>
                            </div>
                        </div>
                        <div className="group relative">
                            <div className="bg-white rounded-lg p-6">
                                <h3 className="text-lg font-semibold text-gray-900">Création (franchise - restauration)</h3>
                                <img src="https://cdn.pixabay.com/photo/2019/07/14/16/29/pen-4337524_1280.jpg" alt="Création" className="mt-4 rounded-lg"/>
                                <p className="mt-2 text-lg text-gray-500">
                                    <span className="font-bold">Durée investissement :</span> 24 à 36 mois <br/>
                                    <span className="font-bold">Taux d’intérêt :</span> 8 à 10 % <br/>
                                    <span className="font-bold">Distribution mensuelle des intérêts :</span> Crédit
                                    amortissable
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Offer;