import React from 'react';
import CardLogo from "./ui/cards/CardLogo";
import Button from "./ui/buttons/Button";
import TrustPilotReview from "../pages/Home/components/TrustPilotReview";
import Propaganda from "../pages/Home/components/Propaganda";
import {useQuery} from "@tanstack/react-query";
import {fetchHomeIndex} from "../services/newApi";
import Loader from "./Loader";
import ErrorWrapper from "./ui/wrappers/ErrorWrapper";

const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;

const Infos = ({userType}) => {
    const isEmprunteur = userType === 'emprunteur';
    const {
        isPending: isLoading,
        data,
        error,
        isError
    } = useQuery({
        queryKey: ["index-homepage"],
        queryFn: fetchHomeIndex,
    });

    if (isLoading) {
        return <div className="mb-12">
            <Loader/>
        </div>
    }

    if (isError) {
        return <div className="mb-12">
            <ErrorWrapper error={error?.message || ""} />
        </div>
    }


    return (
        <div className="lg:ml-8 xl:ml-[135px] max-w-[1170px]">

            <div
                className='flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 lg:shadow-1 bg-white lg:bg-transparent lg:backdrop-blur rounded-lg'>
                <CardLogo
                    logoClass="FaUsers"
                    title={parseInt(data.NbPreteurs).toLocaleString('fr-FR')}
                    text="Prêteurs inscrits"
                    isEmprunteur={isEmprunteur}
                />
                <CardLogo
                    logoClass="FaArrowUpRightDots"
                    title={`${parseInt(data.Sommefinancee).toLocaleString('fr-FR')}€`}
                    text="Prêtés aux entreprises"
                    isEmprunteur={isEmprunteur}
                />
                <CardLogo
                    logoClass="FaShareFromSquare"
                    title={`${parseInt(data.MontantCapitalRembourse).toLocaleString('fr-FR')}€`}
                    text="Reversés aux prêteurs"
                    isEmprunteur={isEmprunteur}
                />
                <CardLogo
                    logoClass="FaRegHourglassHalf"
                    title="2015"
                    text="Année de création"
                    isEmprunteur={isEmprunteur}
                />
            </div>
            {userType === 'preteur' &&
                <div className="flex flex-col items-center md:flex-row mt-12">
                    <div className="mb-8 md:mb-0 md:w-2/5 lg:w-1/2">
                        <Propaganda />
                    </div>
                    <div className="mb-4 flex flex-1 items-center justify-evenly w-full md:w-auto md:mb-0">
                        <Button
                            btnType="secondary"
                            hover={true}
                            text="Investir dès maintenant"
                            link={{external: true, href: `${pretupClientSpace}/login.php`}}
                        />
                        <Button
                            text="En savoir plus"
                            link={{external: false, href: '/preter/preter-a-des-entreprises'}}
                            btnType="secondaryBorder"
                            hover={true}
                        />
                    </div>
                </div>
            }
            <p className="text-sm text-gray-600 text-center mt-10">Les performances passées ne préjugent pas des
                performances futures. <br/>
                Tout investissement comporte des risques.</p>
            {isEmprunteur &&
                <div className="w-full flex justify-center mt-5">
                    <a href="#fundingForm">
                        <button className="text-xl text-white p-3 rounded-lg text-center bg-primary">
                            Tester mon éligibilité
                        </button>
                    </a>
                </div>
            }

        </div>
    )
}

export default Infos;