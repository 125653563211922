import {useEffect, useState} from "react";
import Pastille from "../../../components/ui/wrappers/Pastille";
import H3Base from "../../../components/ui/titles/H3Base";
import Button from "../../../components/ui/buttons/Button";
import BorderWrapper from "../../../components/ui/wrappers/BorderWrapper";
import {formatDate} from "../../../functions/functions";

const CardArticle = ({article, handleClick}) => {
    const [imgSrc, setImgSrc] = useState("");
    const [isErrorImg, setIsErrorImg] = useState(false);

        if (article.PhotosLocal &&
            article.PhotosLocal.length > 0 &&
            imgSrc !== `/images/articles/${article.ArticleId}/${article.PhotosLocal[0]}` &&
            !isErrorImg
        ) {
            setImgSrc(`/images/articles/${article.ArticleId}/${article.PhotosLocal[0]}`);
        }

        const handleErrorImg = () => {
            if (isErrorImg) {
                return;
            }

            setIsErrorImg(true);

            if (article.Photo &&
                article.Photo.length > 0) {
                setImgSrc(article.Photo[0]);
            }
            else {
                setImgSrc('');
            }
        }

    return <BorderWrapper onClick={() => handleClick(article.ArticleId)} tailwindClass={'p-2 max-w-80 group hover:opacity-85 cursor-pointer'}>
        <div className="mb-2 relative w-full h-60 overflow-hidden rounded-md bg-gray-200">
            {article.Pastilles && article.Pastilles.length > 0 && (
                <div className="absolute left-3 top-3">
                    {
                        article.Pastilles.map(((text, index) => {
                            const theClass = index === 0 ?
                                "text-sm bg-white text-secondary py-1 px-2 text-sm"
                                :
                                "mt-2 text-sm bg-white text-secondary py-1 px-2 text-sm";

                            return <Pastille className={theClass}
                                             key={`pastillesProj-${article.ArticleId}-${index}`}>{text}</Pastille>
                        }))
                    }
                </div>
            )}
            <img
                src={imgSrc}
                onError={handleErrorImg}
                alt={article.Titre}
                className="h-full w-full object-cover object-center"
            />
        </div>
        <div className={"h-36 flex flex-col justify-between"}>
            <H3Base text={article.Titre} className={"mb-5 text-center"}  />
            <div className={"flex justify-between items-end"}>
                <span>{formatDate(article.DateCreation.date)}</span>
                <Button
                    text={"Voir plus"}
                    btnType={"secondary"}
                    tailwinClass={"group-hover:scale-110 shrink-0 "}
                    size={"xs"}
                />
            </div>
        </div>
    </BorderWrapper>
}

export default CardArticle;