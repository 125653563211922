import React from 'react';
import { FaBalanceScale, FaCheckCircle, FaSyncAlt, FaShieldAlt } from "react-icons/fa";
import AMF from '../../../assets/amf.svg';
const Process = () => {
    return (
        <div className="mb-12 md:mb14 xl:mb-24">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-4xl font-bold text-white bg-secondary py-3 rounded-lg text-center">Un processus de
                    financement fiable</h2>
                <div className="text-center mt-4 text-gray-600">
                    <p>Notre première garantie, celle de vous proposer un service technologiquement sûr, qui respecte le
                        cadre légal et protège vos informations et vos transactions.
                    </p>
                </div>

                <div className="grid border border-white rounded-lg shadow-sm md:grid-cols-2 bg-white mt-6">
                    <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
                        <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                            <h3 className="text-lg font-bold dark:text-black">Activité réglementée</h3>
                            <p className="my-4 text-gray-900">Nous disposons de l’ensemble des autorisations légales et sommes enregistrés auprès de l’AMF.</p>
                        </blockquote>
                        <FaBalanceScale className='text-secondary text-4xl mb-2' />
                    </figure>
                    <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
                        <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                            <h3 className="text-lg font-bold dark:text-black">Vérification des données</h3>
                            <p className="my-4 text-gray-900">Gestion de la connaissance client et Lutte Anti Blanchiment et Financement du Terrorisme en partenariat avec Lemonway.</p>
                        </blockquote>
                        <FaCheckCircle className='text-secondary text-4xl mb-2' />
                    </figure>
                    <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
                        <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                            <h3 className="text-lg font-bold dark:text-black">Continuité du service</h3>
                            <p className="my-4 text-gray-900">Notre plan de gestion extinctive garantit la continuité des remboursements en cas de défaillance de la plateforme.</p>
                        </blockquote>
                        <FaSyncAlt className='text-secondary text-4xl mb-2' />
                    </figure>
                    <figure
                        className="flex flex-col items-center justify-center p-8 text-center bg-white border-b rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
                        <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                            <h3 className="text-lg font-bold dark:text-black">Gestion des conflits d’intérêt</h3>
                            <p className="my-4 text-gray-900">Notre politique et nos procédures de gestion des conflits
                                d’intérêts ont été validés par l’AMF.
                            </p>
                        </blockquote>
                        <img src={AMF} className="text-4xl mb-2" alt="AMF"/>
                    </figure>
                </div>

            </div>
        </div>
    )
}

export default Process;