import React from 'react';

const steps = [
    { title: 'Ouvrez votre compte', description: "Votre email vous permet de vous inscrire et d’accéder à nos services en quelques minutes." },
    { title: 'Répondez à quelques questions', description: "Vos réponses nous permettent de mieux comprendre vos attentes." },
    { title: 'Créditez votre wallet', description: "Votre argent est conservé chez BNP Paribas via notre prestataire de Service de paiement Lemonway." },
    { title: 'Vous êtes prêts à investir !', description: "Effectuez des offres de prêt aux entreprises rigoureusementsélectionnées par PretUp." }
];

const Stepper = () => {
    return (
        <div className="text-center mb-12 mx-auto max-w-2xl px-4 sm:px-6 md:mb-14 lg:max-w-7xl lg:px-8 xl:mb-20">
            <h2 className="text-4xl font-bold text-white bg-secondary py-3 rounded-lg text-center shadow-lg">
                Réalisez votre 1er prêt en quelques clics
            </h2>
            <div className="text-center mt-4 mb-10 text-gray-600">
                <p>Pas de frais d’inscription.<br />
                    Pas de frais de gestion.<br />
                    Aucun engagement jusqu’à ce que vous soyez prêt à investir.
                </p>
            </div>
            <div className="mb-16">
                {steps.map((step, index) => (
                    <div key={index} className="step-item transition-transform transform hover:scale-105">
                        <div className="flex flex-col items-center mb-4">
                            <div className="w-10 h-10 bg-secondary rounded-full flex items-center justify-center ring-8 ring-white shadow-lg">
                                <span className="text-white font-bold">{index + 1}</span>
                            </div>
                            <h3 className="text-lg font-semibold mt-4 text-secondary">
                                {step.title}
                            </h3>
                        </div>
                        <p className="mb-6 px-10 text-gray-700">
                            {step.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Stepper;
