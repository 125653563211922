const ErrorWrapper = ({error, tailwindClass}) => {
    if (typeof tailwindClass !== 'string') {
        tailwindClass = ""
    }

    const finalClass = "min-h-52 flex items-center justify-center text-xl " + tailwindClass;

    return (
        <div className={finalClass}>
            <p>{error}</p>
        </div>
    );
}

export default ErrorWrapper;