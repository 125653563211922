import H2Base from "./ui/titles/H2Base";
import GridCustom from "../pages/Home/components/GridCustom";
import Loader from "./Loader";
import H3Base from "./ui/titles/H3Base";
import ErrorWrapper from "./ui/wrappers/ErrorWrapper";

const ProjectsView = ({
                          projects,
                          title,
                          error,
                          loading,
                          titleSize = 2,
                          tailwindClass = "",
                          emptyTitle = "Aucun résultat",
                          nbCols = 4,
                          titleClass = ""
                      }) => {
    let titleComponent;
    let finalClass;

    if (typeof tailwindClass !== "string") {
        tailwindClass = "";
    }
    finalClass = tailwindClass;

    switch (titleSize) {
        case 2:
            titleComponent = <H2Base text={title} tailwindClass={titleClass}/>;
            break;
        case 3:
            titleComponent = <H3Base text={title} tailwindClass={titleClass}/>;
            break;
        default:
            titleComponent = <H2Base text={title} tailwindClass={titleClass}/>;
    }

    if (loading) {
        return <div className={finalClass}>
            {titleComponent}
            <Loader/>
        </div>
    }

    if (error) {
        return <div className={finalClass}>
            {titleComponent}
            <ErrorWrapper error={error} tailwindClass={finalClass}/>
        </div>
    }

    if (projects.length === 0) {
        return <div className={finalClass}>
            {titleComponent}
            <ErrorWrapper error={emptyTitle}/>
        </div>
    }


    return (
        <div className={finalClass}>
            {titleComponent}
            <GridCustom items={projects} nbCols={nbCols} config={{maxColumnWidth: 300, gap: 60}}
                        containerStyle={"flex justify-center"}/>
        </div>
    );
}

export default ProjectsView;