import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getBlogArticles} from "../../services/newApi";
import {formatDate} from "../../functions/functions";
import Loader from "../../components/Loader";
import ErrorWrapper from "../../components/ui/wrappers/ErrorWrapper";
import BannerBlog from "./components/BannerBlog";
import DOMPurify from "dompurify";
import "../../styles/article-database.css";
import {useState} from "react";

const ArticleBlog = () => {
    const {id} = useParams();
    const {
        isPending: isLoading,
        data: article,
        error,
        isError
    } = useQuery({
        queryKey: ["blog-article", id],
        queryFn: async () => getBlogArticles(parseInt(id), 0),
    });
    let imageSrc = "";
    let imageSrcError = "";

    if (article &&
        article.PhotosLocal &&
        article.PhotosLocal[0] &&
        article.ArticleId
    ) {
        imageSrc = `/images/articles/${article.ArticleId}/${article.PhotosLocal[0]}`;
    }

    if (article &&
        article.Photo &&
        article.Photo[0]
    ) {
        imageSrcError = article.Photo[0];
    }

    if (isLoading) {
        return <div className="mb-12">
            <Loader/>
        </div>
    }

    if (isError) {
        return <div className="mb-12">
            <ErrorWrapper error={error?.message || ""}/>
        </div>
    }

    return (<div>
        <BannerBlog
            title={article.Titre}
            imgSrc={imageSrc}
            imgSrcError={imageSrcError}
            date={formatDate(article.DateCreation.date)}
            pastilles={article.Pastilles}
        />
        <div className='container-generic' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(article.Contenu)}}/>
    </div>)
}

export default ArticleBlog;