import {getProjects, searchProjects} from "../../services/newApi";
import {useEffect, useState} from "react";
import ProjectsView from "../../components/ProjectsView";
import Button from "../../components/ui/buttons/Button";
import TabContent from "./components/TabContent";
import H2Base from "../../components/ui/titles/H2Base";
import ProjectSearch from "./components/ProjectSearch";
import CTASubscription from "./components/CTASubscription";
import {useQueries, useQuery, useQueryClient} from "@tanstack/react-query";

/**
 * Affiche les projets en cours de collecte.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Projects = () => {
    const [activeTab, setActiveTab] = useState(3);
    const [tabContent, setTabContent] = useState([]);
    const [isTabButtonVisible, setIsTabButtonVisible] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [tabError, setTabError] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState("");
    const {
        isPending: isLoading,
        data: currentProjects,
        error,
    } = useQuery({
        queryKey: ["project-notfinished"],
        queryFn: async => getProjects("notfinished"),
    });
    const [refundedQuery, notstartedQuery, finishedQuery] = useQueries({
        queries: [
            {
                queryKey: ["project-refunded"],
                queryFn: () => getProjects("refunded"),
            },
            {
                queryKey: ["project-notstarted"],
                queryFn: () => getProjects("notstarted"),
            },
            {
                queryKey: ["project-finished"],
                queryFn: () => getProjects("finished"),
            }
        ],
    });
    const queryClient = useQueryClient();
    // La requete utilisée dans le composant de recherche.
    const searchQuery = queryClient.getQueryState(["project-search"]);
    const searchError = searchQuery?.error;
    const isTabLoading = refundedQuery.isLoading || notstartedQuery.isLoading || finishedQuery.isLoading || searchQuery.isLoading;
    // const tabError = refundedQuery.error || notstartedQuery.error || finishedQuery.error || searchError;


    // Le type de projet en fonction du numéro d'onglet choisi.
    const typeByTab = {
        1: "finished",
        2: "refunded",
        3: "notstarted",
    };


    // La requete pour fetch le projets selon le type de projets.
    const queryByType = {
        "refunded": refundedQuery,
        "notstarted": notstartedQuery,
        "finished": finishedQuery
    };



    const handleTabBtnClick = async (nbTab) => {
        const type = typeByTab[nbTab];
        const query = queryByType[type];

        if (query.isSuccess) {
            setTabContent(query.data);
            setTabError(null);
        }
        else if (query.isError) {
            setTabError(query.error);
        }

        setActiveTab(nbTab);
    };

    // Dès que les requêtes des projets à venir et terminées sont finies
    // on affiche les projets à venir s'il y en a, sinon les projets terminés.
    useEffect(() => {
        if (notstartedQuery?.data && notstartedQuery?.data.length > 0) {
            setTabContent(notstartedQuery.data);
        } else if (finishedQuery?.data && finishedQuery?.data.length > 0) {
            setActiveTab(1);
            setTabContent(finishedQuery.data);
        }
    }, [notstartedQuery.data, finishedQuery.data]);


    return (
        <section className="container mx-auto">
            <CTASubscription/>
            <ProjectsView
                emptyTitle={"Des nouveaux projets sont en cours de préparation"}
                isLoading={isLoading}
                error={error?.message || ""}
                projects={currentProjects?.slice(0, 8) || []}
                title="Projets en cours de financement"
            />
            <div className="block lg:flex justify-between mt-16">
                <H2Base tailwindClass="lg:mb-0" text="Autres types de projets"/>
                <ProjectSearch
                    setActiveTab={setActiveTab}
                    setTabContent={setTabContent}
                    setTabError={setTabError}
                    setIsSearching={setIsSearching}
                    setIsTabButtonVisible={setIsTabButtonVisible}
                    searchInputValue={searchInputValue}
                    setSearchInputValue={setSearchInputValue}
                />
            </div>

            <div className="my-16 flex justify-evenly">
                <Button text="Projets à venir" btnType={activeTab === 3 ? "secondary" : "secondaryBorder"}
                        handleClick={() => handleTabBtnClick(3)}/>
                <Button text="Collectes terminées" btnType={activeTab === 1 ? "secondary" : "secondaryBorder"}
                        handleClick={() => handleTabBtnClick(1)}/>
                <Button text="Projets remboursés" btnType={activeTab === 2 ? "secondary" : "secondaryBorder"}
                        handleClick={() => handleTabBtnClick(2)}/>
            </div>
            <TabContent content={tabContent || []} error={tabError?.message || ""} isLoading={isTabLoading}
                        isTabButtonVisible={isTabButtonVisible}/>
        </section>
    )
}

export default Projects;