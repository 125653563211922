import {useLocation} from "react-router-dom";
import {getArticle} from "../../services/newApi";
import ArticleDatabase from "./components/ArticleDatabase";
import "../../styles/article-database.css";
import Loader from "../../components/Loader";
import BottomAdditionalContent from "./components/BottomAdditionalContent";
import TopAdditionalContent from "./components/TopAdditionalContent";
import {useQuery} from "@tanstack/react-query";
import ErrorWrapper from "../../components/ui/wrappers/ErrorWrapper";


const pathToId = {
    '/taux-de-rendement-interne': 117,
    '/lexique': 145,
    '/nous-recrutons': 109,
    '/mentions-legales': 23,
    '/politique-de-confidentialite': 125,
    '/cgu': 91,
    '/a-propos-de': 92,
    '/actualites': 93,
    '/partenaires': 94,
    '/fiscalite': 98,
    '/reclamation': 142,
    '/preter/methodes-de-cotation': 139,
    '/communique-de-presse': 123,
    '/PGE': 127,
    '/avis-clients': 122,
    '/financement-solidaire---associations': 103,
    '/preter/methodes-de-cotation/selection-des-pme': 136,
    '/preter/methodes-de-cotation/analyse-financiere': 133,
    '/preter/methodes-de-cotation/cotation': 134,
    '/preter/methodes-de-cotation/tarification': 138,
    '/preter/methodes-de-cotation/organes-de-decision': 137,
    '/preter/methodes-de-cotation/suivi-des-financements': 135,
    '/garanties': 143
};

const routesBottomAdditionalContent = {
    '/a-propos-de': 'formContact',
    '/preter/methodes-de-cotation': 'btns',
    '/preter/methodes-de-cotation/selection-des-pme': 'btns',
    '/preter/methodes-de-cotation/analyse-financiere': 'btns',
    '/preter/methodes-de-cotation/cotation': 'btns',
    '/preter/methodes-de-cotation/tarification': 'btns',
    '/preter/methodes-de-cotation/organes-de-decision': 'btns',
    '/preter/methodes-de-cotation/suivi-des-financements': 'btns'
};

const routesTopAdditionalContent = {
    '/preter/methodes-de-cotation': 'quotingSteps',
    '/a-propos-de': 'APropos',
};



const GenericTemplate = () => {
    const location = useLocation();
    const idPage = pathToId[location.pathname];
    const {
        isPending: isLoading,
        data: article,
        error,
        isError
    } = useQuery({
        queryKey: ["articles",  idPage],
        queryFn: async () => getArticle(idPage),
    });


    if (isLoading) {
        return <Loader/>;
    }

    if (isError) {
        return <ErrorWrapper error={error.message}/>;
    }

    return <>
        {routesTopAdditionalContent.hasOwnProperty(location.pathname) &&
            <TopAdditionalContent content={routesTopAdditionalContent[location.pathname]}/>
        }
        <ArticleDatabase article={article}/>
        {routesBottomAdditionalContent.hasOwnProperty(location.pathname) &&
            <BottomAdditionalContent content={routesBottomAdditionalContent[location.pathname]}/>
        }
    </>
}

export default GenericTemplate;