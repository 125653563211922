/**
 *
 * @param pathImage
 * @param text
 * @param className string doit être une classe tailwind
 * @returns {JSX.Element}
 * @constructor
 */
const LabelFooter = ({pathImage, text, className}) => {
    let finalClass = "text-center text-white ";

    if (typeof  className === "string") {
        finalClass += className
    }

    return (
      <div className={finalClass}>
          <img src={pathImage}  alt="A society label" className="mb-1 mx-auto w-14"/>
          <p className="text-xs">{text}</p>
      </div>
    );
}

export default LabelFooter;