import ProjectCard from "./ProjectCard";
import useWindowSize from "../../../hooks/useWindowSize";
import {getBreakPoint } from "../../../functions/functions";
import {getNbColumns, isColumnTooLarge} from "../../../functions/grid-custom";

/* TailwindScreen
screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1234px',
    },
 */

/**
 * Composant qui gère automatiquement un affichage en grille en fonction du
 * breakpoint tailwind de l'écran et du nombre d'items passé en props.
 *
 * Le nombre maximale de colonnes est le suivant :
 *
 *
 *              1 item | 2 items | 3 items | 4 items ou plus
 * Breakpoint |
 * sm-        |   1    |   1     |     1   |    1
 *
 * sm         |   1    |    2    |    2    |    2
 *
 * md         |   1    |    2    |    2    |    2
 *
 * lg         |   1    |    2    |    3    |    3
 *
 * xl         |   1    |    2    |    3    |    4
 *
 * @param items object
 *
 * @param config {maxColumnWidth: number , gap: number}
 * Les nombres à renseigner sont en pixels.
 * Le gap correspond au gap horizontal
 *
 * @param nbCols integer > 0 Le nombre de colonnes qu'on souhaite afficher.
 * Ce nombre ne sera automatiquement réduit si la taille d'écran est trop petite.
 *
 * @param tailwindClass string La classe tailwind à appliquer au container.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GridCustom = ({items, config = {maxColumnWidth: 0, gap: 0}, nbCols, containerStyle}) => {
    const nbItems = items.length;
    const windowSize = useWindowSize();
    const currentBreakpoint = getBreakPoint(windowSize.width);
    const nbColumns =  getNbColumns(currentBreakpoint, nbItems, nbCols);
    let colClass = "";
    const columnWidth = config.maxColumnWidth;
    const gap = config.gap || 0;
    const gridStyle = {display: "grid", rowGap: "30px"};

    if (typeof columnWidth === "number" && columnWidth > 0 ) {
        const isColTooLarge = isColumnTooLarge(windowSize.width, columnWidth, nbColumns, gap);

        if (isColTooLarge) {
            // On fait comme si la taille de colonne n'était pas renseignée.
            gridStyle.gridTemplateColumns = `repeat(${nbColumns.toString()},minmax(0, 1fr)`;
        } else {
            colClass = `grid-cols-[repeat(${nbColumns},minmax(0,${columnWidth}px))]`;
            gridStyle.gridTemplateColumns = `repeat(${nbColumns},minmax(0,${columnWidth}px))`;
        }

    } else {
        gridStyle.gridTemplateColumns = `repeat(${nbColumns.toString()},minmax(0, 1fr)`;
    }

    if (gap > 0) {
        gridStyle.columnGap = `${gap}px`;
    }

    if (typeof containerStyle !== "string") {
        containerStyle = "";
    }

    return (
        <div className={containerStyle} style={gridStyle}>
            {items.map((project, index) => (
                <div key={`project-${index}`}
                     className="col-span-1">
                    <ProjectCard project={items[index]} index={index}/>
                </div>
            ))}
        </div>
    );

}

export default GridCustom;