import {Link} from 'react-router-dom';

const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;

const MenuFooter = ({items, title, tailwindStyle}) => {

    return (
        <div className={tailwindStyle?.mainContainer ? `${tailwindStyle?.mainContainer} md:max-w-52` : "md:max-w-52"}>
            <h3 className="text-sm font-semibold uppercase text-white mb-2">{title}</h3>
            <ul className="space-y-2">
                {
                    // On enlève la / les pages qui ne doivent plus être affichées.
                    items.filter(item => item.url !== "PGE")
                        .map((item, index) => {

                        if (item.url === "emprunter/financez-votre-projet") {
                            return (<li key={`lendmenuitem-${index}`}
                                        className="text-sm text-white hover:underline cursor-pointer"
                            >
                                <a href={`${pretupClientSpace}/login.php?emp=1`} >{item.Titre}</a>
                            </li>)
                        }
                        else if (item.url === "webdata/1/procedure_gestion_conflits_d_interets_unitup_pretup.pdf") {
                            return (<li key={`lendmenuitem-${index}`}
                                        className="text-sm text-white hover:underline cursor-pointer"
                            >
                                <a href={`${pretupClientSpace}/webdata/1/procedure_gestion_conflits_d_interets_unitup_pretup.pdf`} target="_blank" >{item.Titre}</a>
                            </li>)
                        }
                        else if (item.url === "webdata/1/procedure_reclamation_Unitup_PretUp.pdf") {
                            return (<li key={`lendmenuitem-${index}`}
                                        className="text-sm text-white hover:underline cursor-pointer"
                            >
                                <a href={`${pretupClientSpace}/webdata/1/procedure_reclamation_Unitup_PretUp.pdf`} target="_blank" >{item.Titre}</a>
                            </li>)
                        }
                        else if (item.url === "cgu_lemonway") {
                            return (
                                <li key={`lendmenuitem-${index}`}
                                    className="text-sm text-white hover:underline cursor-pointer"
                                >
                                    <a href={`${pretupClientSpace}/cgu_lemonway.pdf`}
                                       target="_blank">{item.Titre}</a>

                                </li>)
                        } else if (item.url === "emprunter/emprunter-aupres-des-particuliers") {
                            return (
                                <li key={`lendmenuitem-${index}`}
                                    className="text-sm text-white hover:underline cursor-pointer"
                                >
                                    <Link to="/emprunteur" preventScrollReset={false}>{item.Titre}</Link>
                                </li>)
                        }
                        else {
                            return (item.url &&
                                <li key={`lendmenuitem-${index}`}
                                    className="text-sm text-white hover:underline cursor-pointer"
                                >
                                <Link to={item.url.toLowerCase()} preventScrollReset={false}>{item.Titre}</Link>
                                </li>)
                        }

                    })
                }
            </ul>
        </div>
    );
}

export default MenuFooter;