import React from 'react';
import {useNavigate} from "react-router-dom";

const ButtonBoost = () => {
    const navigate = useNavigate();

    return (
        <button
            onClick={() => {navigate("/pret-automatique-boost-pretup")}}
           className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold px-6 py-3 rounded transition duration-300 ease-in-out transform hover:scale-105">
            En savoir plus
        </button>
    )
};

export default ButtonBoost;