import ProjectsView from "../../../components/ProjectsView";
import {useQuery} from "@tanstack/react-query";
import {fetchProjectsHomeCarousel} from "../../../services/newApi";

const LandingProjects = () => {
    const {
        isPending: isLoading,
        data: projects,
        error,
        isError
    } = useQuery({
        queryKey: ["project-homepage"],
        queryFn: fetchProjectsHomeCarousel,
    });


    return <ProjectsView
        error={error ?? ""}
        projects={projects?.slice(0, 3) || []}
        tailwindClass={"text-center"}
        loading={isLoading}
        title={"Des entreprises vous attendent"}
        nbCols={3}
        titleClass={"mb-8"}
    />
}

export default LandingProjects;