import H1Base from "../../../components/ui/titles/H1Base";
import Button from "../../../components/ui/buttons/Button";
import Pastille from "../../../components/ui/wrappers/Pastille";
import {useState} from "react";

const BannerBlog = ({title, date, imgSrc, pastilles, imgSrcError}) => {
    const [sourceImage, setSourceImage] = useState(imgSrc);

    return <div className={"mx-auto mb-12 md:mb-16 lg:mb-20 flex w-full h-96 lg:w-11/12 hover:opacity-95"}>
        <div className={"pt-2 mr-8 flex-1 flex flex-col justify-between"}>
            <Button
                link={{href: "/blog", external: false}}
                text={"⤶ Retour au blog"}
                btnType={"secondary"}
                size={"small"}
                tailwinClass={"w-fit"}
            />
            <H1Base text={title} className={"text-center w-4/5 leading-[1.2] text-5xl"}/>
            <p className={"text-right"}>Le {date}</p>
        </div>
        <div className="flex-1 relative">
            {pastilles && pastilles.length > 0 &&
                <div className={"absolute right-2 top-2 flex-1 flex flex-col gap-y-2"}>
                    {pastilles.map((text, index) => {
                        return <Pastille
                            className={"bg-secondary w-full text-center text-white py-1 px-2 mx-auto"}
                            key={`fishermanFriends-${index}`}
                        >{text}</Pastille>
                    })
                    }
                </div>
            }
            <img
                className="h-full w-full object-cover object-center"
                src={sourceImage}
                onError={() => setSourceImage(imgSrcError)}
            />
        </div>
    </div>
}

export default BannerBlog;