import { AnimatePresence } from "framer-motion";
import Toast from "../components/Toast";
import {createContext, useContext, useRef, useState} from "react";

const defaultPush = (toast) => {}; // Méthode de base que l'on mettra dans le contexte par défaut

export const ToastContext = createContext({
    pushToastRef: { current: defaultPush },
});

// On entourera notre application de ce provider pour rendre le toasts fonctionnel
export function ToastContextProvider({ children }) {
    const pushToastRef = useRef(defaultPush);
    return (
        <ToastContext.Provider value={{ pushToastRef }}>
            <Toasts />
            {children}
        </ToastContext.Provider>
    );
}
export function Toasts() {
    const [toasts, setToasts] = useState([]);
    // On modifie la méthode du contexte
    const { pushToastRef } = useContext(ToastContext);
    pushToastRef.current = ({ duration, ...props }) => {
        // On génère un id pour différencier les messages
        const id = Date.now();
        // On sauvegarde le timer pour pouvoir l'annuler si le message est fermé
        const timer = setTimeout(() => {
            setToasts((v) => v.filter((t) => t.id !== id));
        }, (duration ?? 5) * 1000);
        const toast = { ...props, id, timer };
        setToasts((v) => [...v, toast]);
    };

    // const onRemove = (toast) => {
    //     clearTimeout(toast.timer);
    //     setToasts((v) => v.filter((t) => t !== toast));
    // };

    return (
        <div className="">
            <AnimatePresence>
                {toasts.map((toast, index) => (
                        <Toast {...toast} key={index} />
                ))}
            </AnimatePresence>
        </div>
    );
}