import {useEffect, useRef} from 'react'
import {useLocation} from "react-router-dom";

const TrustPilotCarousel = ({showTitle = true}) => {
    const trustBoxRef = useRef(null);

    useEffect(() => {
        if(window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustBoxRef.current, true);
        }
    }, []);

    return (
        <section className="mb-16 mx-auto max-w-2xl px-4 sm:px-6 md:mb14 lg:max-w-7xl lg:px-8 xl:mb-24">
            {showTitle && <h2 className="base-h2-secondary mb-16">L'avis des investisseurs</h2>}

            <div ref={trustBoxRef} className="trustpilot-widget" data-locale="fr-FR"
                 data-template-id="53aa8912dec7e10d38f59f36"
                 data-businessunit-id="578f3a6e0000ff000592886e" data-style-height="140px" data-style-width="100%"
                 data-stars="5" data-review-languages="fr">
                <a href="https://fr.trustpilot.com/review/www.pretup.fr" target="_blank" rel="noopener">Trustpilot</a>
            </div>
        </section>

    );
}
export default TrustPilotCarousel;