import React from "react";
import Banner from "../../components/Banner";
import Offer from "../../components/Offer"
import Form from "./components/Form";
import Cards from "../../components/Cards";

const HomeEmprunteur = () => {
    const userType = 'emprunteur';
    const cards = [
        {
            title: "Développer votre activité",
            content: (
                <ul className="list-disc m-2">
                    <li>Dotez-vous de ressources complémentaires pour accélérer</li>
                    <li>Acquisition de nouveaux équipements</li>
                    <li>Accroissement de votre BFR</li>
                    <li>Développement d'une nouvelle activité</li>
                    <li>Frais de lancement marketing et commercial</li>
                    <li>Recrutement</li>
                </ul>
            ),
            bgColor: 'bg-primary',
            textColor: 'text-white'
        },
        {
            title: "Renforcer votre trésorerie",
            content: (
                <ul className="list-disc m-2">
                    <li>Conservez votre liberté d'entreprendre</li>
                    <li>Anticipation des fluctuations de trésorerie</li>
                    <li>Constitution de stocks</li>
                    <li>Préfinancement de vos créances mais aussi vos futures aides publiques</li>
                    <li>Minimiser l'impact des délais de paiement de vos grands donneurs d'ordre</li>
                </ul>
            )
        },
        {
            title: "Conquérir de nouveaux marchés",
            content: (
                <ul className="list-disc m-2">
                    <li>Explorez de nouvelles opportunités business en France ou à l'international</li>
                    <li>Lancement de nouveaux produits ou de nouvelles offres</li>
                    <li>Ventes à l'export</li>
                    <li>Prospection de nouveaux marchés</li>
                    <li>Investissement ou création de filiales à l'étranger</li>
                </ul>
            )
        },
        {
            title: "Servir votre ambition",
            content: (
                <ul className="list-disc m-2">
                    <li>Faites un premier pas en direction du futur;</li>
                    <li>Opération de croissance externe;</li>
                    <li>Acquisition de murs ou de bâtiments;</li>
                    <li>Renforcement de vos équipes et recrutement de nouveaux talents;</li>
                    <li>Digitalisation ou modernisation de vos outils de production.</li>
                </ul>
            ),
            bgColor: 'bg-primary',
            textColor: 'text-white'
        }
    ];

    const backgroundClass = userType === 'emprunteur' ? 'bg-primary' : 'bg-secondary';
    const lightClass = userType === 'emprunteur' ? 'bg-primaryLight' : 'bg-secondaryLight';
    const darkClass = userType === 'emprunteur' ? 'bg-primaryDark' : 'bg-secondaryDark';

    return (
        <div>
            <Banner userType={userType}/>
            <h2 className="base-h2-primary mb-12">Quelle que soit votre situation, nous avons la solution.</h2>
            <div className="px-6 mb-12 md:mb14 xl:mb-24">
                <Cards
                    cards={cards}
                    className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-8"
                    cardType="card"
                />
            </div>
            <Offer
                backgroundClass={backgroundClass}
                lightClass={lightClass}
                darkClass={darkClass}
            />
            {/*<Testimonials />*/}
            <Form/>
        </div>
    );
};

export default HomeEmprunteur;