const ButtonProjectCard = ({
                               className,
                               projectType
                           }) => {
    let finalClass = "inline-block rounded-md text-center text-bold text-white  ";
    let content = "";
    const enCoursClass = "bg-secondary ";

    switch (projectType) {
        case "avenir":
            finalClass += enCoursClass;
            content = "Découvrir";
            break;
        case "encours":
            finalClass += enCoursClass;
            content = "Prêter";
            break;
        case "termine":
        case "rembourse":
            finalClass += "bg-green-600 ";
            content = "Clôturé";
            break;
        default:
            console.error("Etat de projet inconnu.")
            break;

    }
    finalClass += className;

    if (!content) {
        return null;
    }

    return <span className={finalClass}>{content}</span>;
}
export default ButtonProjectCard;