import H2Base from "../../../components/ui/titles/H2Base";
import Card from "../../../components/Card";
import ValeurItem from "./ValeurItem";
import AProposRelations from "./AProposRelations";
import {generateContactLink} from "../functions/genericTemplateFunctions";
import H1Base from "../../../components/ui/titles/H1Base";

const contacts = {
    fabien: {
        mail: "mailto:fabien.michel@pretup.fr",
        tel: "tel:+33383416452",
        linkedin: "https://www.linkedin.com/in/fabien-michel-7967644b/"
    },
    analystes: [
        {
            name: "Laura LANDANGER",
            mail: "mailto:laura.landanger@pretup.fr",
            tel: "tel:+33756040292",
            linkedin: "https://www.linkedin.com/in/laura-l-228348264/",
            job: "Analyste financement",
            image: "/images/pages/a-propos/LL.jpg"
        },
        {
            name: "Sabrina OLRY",
            mail: "mailto:sabrina.olry@pretup.fr",
            tel: "tel:+33756042406",
            linkedin: "https://www.linkedin.com/in/sabrina-olry-870161b4/",
            job: "Analyste financement",
            image: "/images/pages/a-propos/SO.jpg"
        },
        {
            name: "Pierrick RICHET",
            mail: "mailto:pierrick.richet@pretup.fr",
            tel: "tel:+33756040295",
            linkedin: "https://www.linkedin.com/in/pierrick-r-73886086/",
            job: "Analyste financement",
            image: "/images/pages/a-propos/PR.jpg"

        },
        {
            name: "Vincent MALNOY",
            mail: "mailto:vincent.malnoy@pretup.fr",
            tel: "tel:+33756040293",
            linkedin: "https://www.linkedin.com/in/vincent-m-b5b35757/",
            job: "Analyste financement",
            image: "/images/pages/a-propos/VM.jpg"

        },

    ],
    business_developer: [
        {
            name: "Rim EL AOUDI",
            mail: "mailto:rim.elaoudi@pretup.fr",
            tel: "tel:+33383416452",
            linkedin: "https://www.linkedin.com/in/rimelaoudi/",
            image: "/images/pages/a-propos/RE.jpg",
            job: "Business Developper",

        }
    ],
    developer: [
        {
            name: "Xavier MOREAU",
            mail: "mailto:xavier.moreau@pretup.fr",
            tel: "tel:+3383416452",
            job: "Développeur backend",
            linkedin: "https://www.linkedin.com/in/xavier-moreau-devweb/",
            image: "/images/pages/a-propos/XM.jpg",

        },
        {
            name: "David DONNOT",
            mail: "mailto:david.donnot@pretup.fr",
            tel: "tel:+3383416452",
            job: "Développeur frontend",
            linkedin: "https://www.linkedin.com/in/david-donnot-nancy/",
            image: "/images/pages/a-propos/DD.jpg"
        },
    ]
}

const valeurs = [
    ["Partage", "PretUp favorise une économie participative où chaque investisseur peut contribuer au financement des entreprises. Ce partage des ressources permet de soutenir des projets concrets et de dynamiser l'économie locale et nationale"],
    ["Réactivité", "La plateforme assure un processus rapide et fluide pour les emprunteurs et les prêteurs. Grâce à des outils numériques performants et un accompagnement efficace, PretUp répond aux demandes en un minimum de temps"],
    ["Engagement", "PretUp s'engage à soutenir les entreprises dans leur croissance tout en garantissant une sécurité maximale aux investisseurs. Cet engagement se traduit par une sélection rigoureuse des projets et un suivi continu"],
    ["Transparence", "La plateforme met un point d'honneur à fournir des informations claires et précises sur les projets financés, les risques encourus et les performances des investissements. La confiance des utilisateurs est au cœur de son fonctionnement"],
    ["Union", "PretUp crée un lien entre les entrepreneurs et les investisseurs, renforçant ainsi la solidarité économique. Cette union permet de bâtir un écosystème vertueux où chacun trouve sa place et contribue au développement"],
    ["Proactivité", "La plateforme anticipe les besoins du marché et innove constamment pour offrir des solutions adaptées aux entreprises et aux investisseurs. Elle analyse les tendances et optimise les services pour améliorer l'expérience utilisateur"],
];

const AProposTeam = () => {


    return <>
        <section className={"mb-8 md:mb-12"}>
            <H1Base text={"Qui sommes nous ?"} className={"mb-8 text-center"}/>
            <p className={"mb-4 text-lg"}>Profitant de la révolution du digital dans les services financiers et du mouvement portée par la
                finance
                participative, j’ai fondé en 2015 PretUp pour créer un point de rencontre entre des investisseurs
                engagés recherchant un placement concret & performant, et des entrepreneurs à la tête de PME en
                croissance</p>
            <img src={"/images/pages/a-propos/FM.jpg"} alt={"Fabien Michel CEO of Pretup"}
                 className={"mx-auto mb-3 max-w-64"}/>
            <Card
                title={"Fabien Michel"}
                titleClass={"text-center"}
                textColor={"black"}
                className={"mx-auto mb-5 w-72 md:w-96"} // La largeur doit être la même que la div du dessous pour maintenir l'alignement.
                subtitle={"Fondateur"}
                subtitleClass={"mb-2 text-gray-700 text-lg font-semibold"}
                content={"20 années d’expériences professionnelles en Direction Financière et systèmes d’informations (Axa Banque, BNP Paribas, Renault Banque, Isodev, Banca Intesa)"}
            />
            <div className={"mx-auto flex w-72 md:w-96 gap-x-5"}>
                {Object.keys(contacts.fabien).map((key, index) => {
                    const value = contacts.fabien[key];
                    return generateContactLink(key, value, index);
                })
                }
            </div>
        </section>
        <section className={"mb-8 md:mb-12"}>
            <H2Base text={"Nos valeurs sont au cœur de notre identité :"} />
            <p className={"mb-4 text-2xl font-semibold"}>
                <span className="text-secondary">P</span>artage{" "}
                <span className="text-secondary">R</span>éactivité{" "}
                <span className="text-secondary">E</span>ngagement{" "}
                <span className="text-secondary">T</span>ransparence{" "}
                <span className="text-secondary">U</span>nion{" "}
                <span className="text-secondary">P</span>roactivité{" "}
            </p>
            <p>L’exigence est au cœur de notre ADN chez PretUp. Chaque demande de financement est minutieusement analysée selon des critères rigoureux, garantissant la solidité et potentiel de performance de chaque entreprise.</p>
            <p className={"mb-5"}>Les particuliers peuvent accéder au financement de l’économie réelle</p>
            <ul className={"list-disc pl-4"}>
                {valeurs.map((valeur, index) => <ValeurItem
                    valeur={valeur[0]}
                    valeurClass={"text-secondary text-lg font-semibold"}
                    text={valeur[1]}
                    textClass={"mb-2"}
                    key={index}
                    />)
                }
            </ul>
            <p>Ces valeurs font de PretUp une plateforme de référence en France du crowdlending et engagée pour le financement des entreprises françaises.</p>
        </section>
        <section>
            <AProposRelations title={"Relations entreprises"} className={"mb-8"} relations={contacts.analystes} generateContactLink={generateContactLink}  />
            <AProposRelations title={"Relations investisseurs"} className={"mb-8"} relations={contacts.business_developer} generateContactLink={generateContactLink}  />
            <AProposRelations title={"Développeurs informatique"} className={"mb-8"} relations={contacts.developer} generateContactLink={generateContactLink}  />
        </section>
    </>
}

export default AProposTeam;