import {FaLightbulb, FaChartPie, FaClipboardCheck} from "react-icons/fa";
import {motion} from "framer-motion";

const icons = {
    "FaLightbulb": FaLightbulb,
    "FaChartPie": FaChartPie,
    "FaClipboardCheck": FaClipboardCheck,
}

const itemVariants = {
    hidden: {opacity: 0, y: 20},
    visible: {opacity: 1, y: 0, transition: {duration: 0.6}}
};
const CardService = ({index, classIcon, title, descriptions}) => {
    const LogoComponent = icons[classIcon];

    return (
        <motion.div className="p-2 relative flex flex-col items-center justify-center border border-t-0 border-l-0 shadow-md lg:shadow-none" variants={itemVariants}>

                <div
                    className="flex items-center justify-center w-12 h-12 bg-secondaryLight rounded-full ring-0 ring-white sm:ring-8 shadow-md">
                    <LogoComponent/>
                </div>
                <div className="mt-3 sm:mt-6 flex-1 flex flex-col text-center lg:text-left">
                    <h3 className="mb-2 text-lg font-semibold text-gray-900">{title}</h3>
                    <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                        {descriptions && descriptions.length > 0 &&
                            descriptions.map((description, index) => <p key={`cardService--${index}`}>{description}</p>)
                        }
                    </div>


                </div>
        </motion.div>
    )
}

export default CardService;