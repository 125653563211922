import {client} from "./axiosClient";
import axios from "axios";
import {formatDatasProject, formatDate} from "../functions/functions";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const checkMail = async (email) => {
    return await axios.post(`${apiBaseUrl}/checkmail`, {email: email});
}

export const getArticle = async (idArticle) => {
    try {
        const response = await client.get(`/article/${idArticle}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

        const article = response.data.datas[0];
        if (article) {
            return article;
        } else {
            throw new Error();
        }
    } catch (error) {
        throw new Error("⚠ Impossible d'afficher le contenu de la page ⚠");
    }
}

export const getFaqArticles = async (type) => {
    if (!type) {
        throw new Error("⚠ Impossible de récupérer les données ⚠");
    }

    try {
        const response = await axios.get(`${apiBaseUrl}?route=get_faq_articles&type=${type}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (typeof response?.data?.datas === "object") {
            return response.data.datas;
        } else {
            throw new Error();
        }
    } catch (error) {
        throw new Error("⚠ Impossible de récupérer les données ⚠");
    }
}

export const getBlogArticles = async (id = 0, index = 1) => {
    if (typeof id !== "number" || typeof index !== "number") {
        if (process.env.NODE_ENV === "development") {
            console.error("Le paramètre id passé à la fonction doit être un entier positif");
        }
        throw new Error("⚠ Impossible d'afficher le contenu de la page ⚠");
    }

    const blogRubriqueId = process.env.REACT_APP_BLOG_ID;
    if (!parseInt(blogRubriqueId)) {
        if (process.env.NODE_ENV === "development") {
            console.error("La variable d'environnement blogRubriqueId est incorrecte");
        }
        throw new Error("⚠ Impossible d'afficher le contenu de la page ⚠");
    }

    let endpoint = `/blog/${blogRubriqueId}/${index}/${id}`;

    try {
        const response = await client.get(endpoint, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

        const articles = response.data.datas;
        if (articles) {
            if (id === 0) return articles;
            else return articles[0];
        } else {
            throw new Error();
        }
    } catch (error) {
        throw new Error("⚠ Impossible d'afficher le contenu de la page ⚠");
    }
}


export const getMenu = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/?route=get_main_menu`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if (Array.isArray(response.data.datas)) {
            return response.data.datas;
        } else {
            throw new Error();
        }
    } catch (error) {
        throw new Error("⚠ Impossible de récupérer les menu ⚠");
    }


}

export const sendContactForm = async (formData) => {
    try {
        await axios.post(`${apiBaseUrl}/contact`, {...formData});
        return true;
    } catch (error) {
        return false;
    }
}

export const getBorrowerLocalisation = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/localisation_borrower`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (typeof response.data === 'object') {
            // Transformation de l'objet en tableau.
            return Object.keys(response.data).map((key) => {
                return response.data[key];
            });
        } else {
            throw new Error();
        }
    } catch (error) {
        throw new Error("⚠ Impossible de récupérer les localisations ⚠");
    }

}

export const getLendersLocalisation = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/localisation_lenders`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error();
        }

    } catch {
        throw new Error();
    }

}

export const getDepartmentsBoundaries = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/department_boundaries`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error();
        }
    } catch {
        throw new Error();
    }

}

export const getStatisticsTableDatas = async () => {
    try {
        const response = await axios.get(`${apiBaseUrl}/?route=get_tableau_statistiques`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if (typeof response.data === 'object') {
            return response.data;
        } else {
            throw new Error();
        }
    } catch {
        throw new Error("⚠ Impossible de récupérer les données ⚠");
    }
}

export const getDiversificationsDatas = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/?route=get_nuage_rentabilite_preteurs`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (typeof response.data === 'object') {
            return Object.keys(response.data).map((key) => {
                return response.data[key]
            });
        } else {
            throw new Error();
        }
    } catch {
        throw new Error("⚠ Impossible de récupérer les données ⚠")
    }
}

export const getSimulatorData = async (amount) => {

    try {
        const response = await axios.post(`${apiBaseUrl}/get_simulator_data`, {
            amount: amount,
            headers: {
                'Content-Type': 'application/json',
            }
        })

        return response.data;
    } catch {
        throw new Error("Impossible de récupérer les données");
    }

}

export const getProjects = async (type, limit = 0) => {
    let url = "";

    if (typeof limit !== "number") {
        limit = 0;
    }

    if (type === "finished" ||
        type === "notstarted" ||
        type === "notfinished" ||
        type === "refunded"
    ) {
        url = `${apiBaseUrl}/?route=get_projects&state=${type}`;
    } else {
        url = `${apiBaseUrl}/?route=get_projects`;
    }

    if (limit > 0) {
        url += `&limit=${limit}`;
    }

    try {
        const response = await axios.get(url);

        if (Array.isArray(response.data.datas)) {
            return response.data.datas.map(project => formatDatasProject(project));
        } else {
            throw new Error();
        }
    } catch {
        throw new Error("⚠ Impossible de récupérer les projets ⚠");
    }

}

export const searchProjects = async (search) => {
    if (typeof search !== "string") {
        throw new Error();
    }


    try {
        const response = await axios.get(`${apiBaseUrl}/?route=get_projects&search=${search}`);

        if (Array.isArray(response.data.datas)) {
            return response.data.datas.map(project => formatDatasProject(project));
        } else {
            throw new Error();
        }
    } catch {
        throw new Error("⚠ Impossible d'effectuer la recherche ⚠");
    }

}

export const fetchProjectsHomeCarousel = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/?route=get_projects&index=1&limit=10`);

        const projects = response.data?.datas;
        if (Array.isArray(projects)) {
            return response.data.datas.map(project => formatDatasProject(project));
        } else {
            throw new Error("Bad response format.");
        }
    } catch (error) {
        throw new Error("⚠ Impossible de récupérer les projets ⚠");
    }

}

export const getMapPreteurDatas = async () => {
    try {
        const departmentsBoundaries = await getDepartmentsBoundaries();

        const lendersLocalisation = await getLendersLocalisation();

        return {lendersLocalisation, departmentsBoundaries};
    } catch (e) {
        throw new Error("⚠ Impossible de récupérer les données ⚠");
    }
}

export const fetchHomeIndex = async () => {
    try {
        const response = await axios.get(`${apiBaseUrl}/?route=get_index_datas`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const data = response.data;

        if (typeof data === "object" && Object.keys(data).length > 0) {
            return data;
        } else {
            throw new Error();
        }
    } catch {
        throw new Error("⚠ Impossible de récupérer les données ⚠");
    }
}
