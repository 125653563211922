import {IoIosMail} from "react-icons/io";
import {LuPhone} from "react-icons/lu";
import {FaLinkedinIn} from "react-icons/fa";
import IconLink from "../../../components/IconLink";

export const generateContactLink = (type, href, index, iconSize = 30) => {
    if (!['mail', "tel", "linkedin"].includes(type)) {
        return null;
    }

    let IconComponent = null;
    switch (type) {
        case "mail":
            IconComponent = IoIosMail;
            break;
        case "tel":
            IconComponent = LuPhone;
            break;
        case "linkedin":
            IconComponent = FaLinkedinIn;
            break;
    }

    return <IconLink key={`${type}-Icon-${index}`} href={href} IconElement={IconComponent} iconSize={iconSize} className="border border-gray-950 p-2 rounded-lg" />;
};
