import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../../../assets/boost-logo.svg';
import Correct from '../../../assets/correct.svg';
import Flag from '../../../assets/flag.svg';
import Layout from '../../../assets/layout.svg';
import Switch from '../../../assets/switch.svg';
import ButtonBoost from "./ButtonBoost";

const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.8,
            staggerChildren: 0.2
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const Boost = () => {
    return (
        <div className="container mx-auto mb-12 md:mb14 xl:mb-24">
            <motion.div
                className="flex flex-wrap bg-white border-2 border-yellow-300 rounded-lg p-8 shadow-2xl"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <motion.div
                    className="w-full sm:w-1/3 px-4 mb-8 flex flex-col items-center text-center"
                    variants={itemVariants}
                >
                    <img src={Logo} alt="boost-pretup" className="w-30 h-30 mb-4"/>
                    <h2 className="text-2xl font-bold text-black mb-4">
                        Diversifiez automatiquement votre portefeuille de prêts.
                    </h2>
                    <p className="text-gray-600 mb-6">
                        Prêtez automatiquement sur les projets qui correspondent aux critères que vous avez sélectionnés.
                    </p>
                    <ButtonBoost />
                </motion.div>
                <motion.div
                    className="w-full sm:w-2/3 px-4 flex flex-wrap items-center justify-center"
                    variants={containerVariants}
                >
                    {[{ img: Correct, text: "Service gratuit" }, { img: Flag, text: "Facile d’utilisation" }, { img: Layout, text: "Diversification des prêts" }, { img: Switch, text: "Désactivable à tout moment" }].map((item, index) => (
                        <motion.div
                            key={index}
                            className="w-1/2 px-4 mb-8 flex flex-col items-center text-center"
                            variants={itemVariants}
                        >
                            <img src={item.img} alt={item.text} className="w-16 h-16 mb-4"/>
                            <h3 className="text-lg font-medium text-gray-700">{item.text}</h3>
                        </motion.div>
                    ))}
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Boost;