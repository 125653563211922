const BoostPretup = ({items, title, tailwindStyle}) => {

    return (
        <div className="boost-pretup">
            <section className="md:flex gap-5 mb-28">
                <div className="mb-8 text-center md:mb-0 md:w-72">
                    <h2 className="text-primaryLight font-bold text-xl">OFFRE PROMOTIONNELLE</h2>
                    <div className="my-3 text-secondary font-bold text-2xl">Jusqu'à 250€ offerts avec Boost Pretup !
                    </div>
                    <button className="btn btn--secondary">
                        Profiter de l'offre
                    </button>
                </div>
                <div className="flex-1 flex flex-col items-center gap-y-10 md:flex-row md:flex-wrap md:justify-evenly">
                    <div className="w-80 md:w-[calc(50%-3rem)] lg:w-[calc(33%-3rem)]">
                        <img src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/offre-40-xs.png"
                             className="md:w-full"
                             alt="40€ offerts pour 2500€ versés"/>
                    </div>
                    <div className="w-80 md:w-[calc(50%-3rem)] lg:w-[calc(33%-3rem)]">
                        <img src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/offre-100-xs.png"
                             className="md:w-full"
                             alt="100€ offerts pour 5000€ versés"/>
                    </div>
                    <div className="w-80 md:w-[calc(50%-3rem)] lg:w-[calc(33%-3rem)]">
                        <img src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/offre-250-xs.png"
                             className="md:w-full"
                             alt="250€ offerts pour 10000€ versés"/>
                    </div>
                </div>
            </section>
            <section className="mb-20">
                <div className="text-center text-lg md:text-left md:text-xl lg:text-2xl">

                    <h2 className="my-3 text-secondary font-semibold text-2xl md:text-3xl lg:text-4xl">Une gestion
                        automatique et
                        diversifiée de votre portefeuille</h2>
                    <p className="mb-2">Libérez-vous des contraintes et gagnez du temps sur la gestion de
                        votre portefeuille de
                        prêts aux entreprises ! Pretup propose à ses prêteurs un nouveau service
                        gratuit et innovant permettant de prêter automatiquement sur les nouveaux projets.</p>
                    <p className="mb-7">Vous définissez vos critères de sélection ainsi que le montant
                        d'investissement souhaité,
                        l'outil Boost Pretup s'occupe du reste et vous tient informé.</p>

                    <div className="mb-7 w-full flex flex-wrap gap-y-10 justify-evenly text-center">
                        <div className="w-full md:w-2/5 lg:w-1/4 flex flex-col items-center">
                            <img
                                src="https://monespace.pretup.fr/websites/pretup-1/images/espace_preteur/boost_check.svg"
                                className="mb-3" alt="Service gratuit" title="Service gratuit"/>
                            <p className="mb-2">Service gratuit</p>
                        </div>

                        <div className="w-full md:w-2/5 lg:w-1/4 flex flex-col items-center">
                            <img
                                src="https://monespace.pretup.fr/websites/pretup-1/images/espace_preteur/boost_flag.svg"
                                className="mb-3" alt="Facile d'utilisation"
                                title="Facile d'utilisation"/>
                            <p className="mb-2">Facile d'utilisation</p>
                        </div>

                        <div className="w-full md:w-2/5 lg:w-1/4 flex flex-col items-center">
                            <img
                                src="https://monespace.pretup.fr/websites/pretup-1/images/espace_preteur/boost_square.svg"
                                className="mb-3" alt="Diversification de mes prêts"
                                title="Diversificationde mes prêts"/>
                            <p className="mb-2">Diversification de mes prêts</p>
                        </div>

                        <div className="w-full md:w-2/5 lg:w-1/4 flex flex-col items-center">
                            <img
                                src="https://monespace.pretup.fr/websites/pretup-1/images/espace_preteur/boost_switch.svg"
                                className="mb-3" alt="Désactivable à tout moment"
                                title="Désactivable à tout moment"/>
                            <p className="mb-2">Désactivable à tout moment</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-7 flex flex-col text-lg md:text-xl lg:text-2xl lg:flex-row">
                <div className="mb-7 text-center lg:mb-0 lg:text-left">
                    <h2 className="text-secondary font-semibold text-2xl md:text-3xl lg:text-4xl">Comment utiliser Boost
                        Pretup ?</h2>
                    <p className="mb-7">L'outil est accessible depuis l'espace personnel Pretup.
                        Sans frais ni engagement,
                        le prêteur peut l'activer à tout moment dès son inscription terminée.</p>
                    <button className="btn btn--white font-semibold text-lg">
                        <a href="/preteur/boost_Pretup.php" className="">
                            Connexion
                        </a>
                    </button>
                </div>
                <img src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/boost-screen.png"
                     className="hidden md:block"
                     alt="Comment utiliser Boost Pretup"/>
            </section>
            <section className="max-w-screen-lg text-center md:text-left">
                <div className="mb-28 flex flex-col items-center md:mb-7 md:items-start md:flex-row">
                    <img src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/plafond.svg"
                         className="flex-shrink-0 w-72 md:w-96" alt="Plafond d'investissement"/>
                    <div className="">
                        <h3 className="mb-2 text-secondary font-semibold text-2xl md:mt-14 md:text-3xl lg:text-4xl">1.PLAFOND D'INVESTISSEMENT</h3>
                        <p className="mb-2">Vous sélectionnez tout d'abord le montant unitaire d'une
                            offre de prêt. Le plafond
                            est limité à 200€ pour les prêteurs débutant sur Pretup.</p>
                    </div>
                </div>
                <div className="mb-28 ">
                    <h3 className="mb-7 text-secondary font-semibold text-2xl md:text-3xl lg:text-4xl">2.MODE D’INVESTISSEMENT</h3>
                    <p className="mb-2">Deux modes de financement automatique sont proposés :</p>
                    <h4 className="my-3 text-accent font-semibold text-xl">Mode simple :</h4>
                    <p className="mb-2">Pretup prête sur les
                        projets si votre solde est
                        suffisant (le montant de l'offre dépend du montant paramétré).
                    </p>
                    <h4 className="my-3 text-accent font-semibold text-xl">Mode avancé :</h4>
                    <p className="mb-2">Boost Pretup prête sur
                        les projets en fonction
                        des critères d'investissement paramétrés. Personnalisez vos critères parmi le
                        plafond d'investissement, la durée des projets, leur taux d'intérêt, leur classe
                        de risque, ainsi que leur secteur géographique !</p>

                </div>
                <div className="mb-28 flex flex-col items-center md:mb-7 md:items-start md:flex-row">
                    <div className="order-2 md:order-1">
                        <h3 className="mb-2 text-secondary font-semibold text-2xl md:text-3xl md:mt-14 lg:text-4xl">3.DIVERSIFICATION</h3>
                        <p className="mb-2">En fonction de vos critères de sélection, l'outil vous aide
                            à savoir le nombre
                            d'entreprises déjà financées sur Pretup. Vous pouvez ainsi ajuster vos
                            critères de diversification si besoin.</p>
                    </div>
                    <img src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/diversification.svg"
                         className="flex-shrink-0 w-72 order-1 md:w-96 md:order-2" alt="Diversification"/>
                </div>
                <div className="mb-28 flex flex-col items-center md:mb-7 md:items-start md:flex-row">
                    <img src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/desactivation.svg"
                         className="flex-shrink-0 w-72 md:w-96" alt="Désactivation"/>
                    <div className="text-center order-2 md:text-left md:order-1">
                        <h3 className="mb-2 text-secondary font-semibold text-2xl md:text-3xl md:mt-14 lg:text-4xl">4.DÉSACTIVATION</h3>
                        <p className="mb-2">A tout moment, vous pouvez désactiver l'outil (plus aucune
                            offre de prêt automatique
                            ne sera émise).</p>
                    </div>
                </div>
                <div className="mb-28 flex flex-col items-center md:mb-7 md:items-start md:flex-row">
                    <div className="order-2 md:order-1">
                        <h3 className="mb-2 text-secondary font-semibold text-2xl md:text-3xl md:mt-14 lg:text-4xl">5.SUIVI DES VERSEMENTS</h3>
                        <p className="mb-2">Les offres automatiques Boost Pretup sont
                            émises quelques heures après
                            l'ouverture de la collecte en fonction du montant du projet (au maximum 24
                            heures après).</p>
                    </div>
                    <img
                        src="https://monespace.pretup.fr/websites/pretup-1/images/page_boost/suivi-versements.svg"
                        className="flex-shrink-0 w-72 order:1 md:w-96  md:order-2" alt="Suivi des versements"/>
                </div>
            </section>
            <section>
                <div className="mb-28 text-center">
                    <h2 className="text-secondary text-4xl font-semibold mb-16">Démarrez dès maintenant votre
                        investissement automatique :</h2>
                    <a href="/preteur/boost_pretup.php"
                       className="p-5 font-semibold inline-block bg-accent rounded-3xl mx-auto hover:bg-white hover:border-secondaryDark hover:border-2">Activer
                        Boost Pretup</a>
                </div>
                <div className="text-l text-primaryLight">
                    <p className="mb-2">(1) Conditions de l'offre promotionnelle :</p>
                    <p className="mb-2">Offre valable à partir du 1er janvier 2024 au 31 décembre 2024. L'offre
                        est valable pour un
                        nouveau prêteur ou une personne ayant déjà un compte Pretup (compte validé
                        -&gt; pièces d'identité fournies). Le prêteur doit activer le module Boost Pretup
                        (mode simple ou avancé) et ne pas retirer des fonds pendant un an.</p>
                    <p className="mb-2">Offre non cumulable avec toute autre offre en cours, limitée à une
                        offre par personne. La prime
                        sera versée sur le compte Pretup au plus tard le 31 décembre 2024.</p>
                    <p className="mb-2">En cas de retrait des fonds par le prêteur au cours des 12 mois suivant
                        le versement,
                        Pretup se réserve le droit de prélever sur ledit compte le montant correspondant à la prime de
                        bienvenue dont il a bénéficié dans le cadre de l'offre commerciale.</p>
                    <p className="mb-2">Pretup se réserve la possibilité à tout moment de modifier,
                        suspendre ou mettre fin à
                        cette offre sous réserve de la diffusion d'une information sur le site www.Pretup.fr
                        moyennant le respect d'un préavis de 10 jours calendaires.</p>
                    <p className="mb-2">Document publicitaire dépourvu de valeur contractuelle.</p>
                    <p className="mb-2">Rappel : prêter de l'argent aux PME/TPE présente un risque de non
                        remboursement et de perte en
                        capital. Pretup est agréée par l'Autorité des Marchés Financiers (AMF) sous le
                        n°FP-2024-7.</p>

                </div>
            </section>
        </div>
    );
}

export default BoostPretup;