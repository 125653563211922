import {useLocation} from "react-router-dom";
import Cards from "../../../components/Cards";
import AProposTeam from "./AProposTeam";


const TopAdditionalContent = ({content}) => {
    const cards = [
        {
            title: 1,
            content: 'Sélection des PME',
            bgColor: 'bg-secondary',
            textColor: 'text-white',
            link: '/preter/methodes-de-cotation/selection-des-pme'
        },
        {
            title: 2,
            content: 'Analyse financière',
            bgColor: 'bg-secondary',
            textColor: 'text-white',
            link: '/preter/methodes-de-cotation/analyse-financiere'
        },
        {
            title: 3,
            content: 'Cotation',
            bgColor: 'bg-secondary',
            textColor: 'text-white',
            link: '/preter/methodes-de-cotation/cotation'
        },
        {
            title: 4,
            content: 'Tarification',
            bgColor: 'bg-secondary',
            textColor: 'text-white',
            link: '/preter/methodes-de-cotation/tarification'
        },
        {
            title: 5,
            content: 'Organes de décision',
            bgColor: 'bg-secondary',
            textColor: 'text-white',
            link: '/preter/methodes-de-cotation/organes-de-decision'
        },
        {
            title: 6,
            content: 'Suivi des financements',
            bgColor: 'bg-secondary',
            textColor: 'text-white',
            link: '/preter/methodes-de-cotation/suivi-des-financements'
        }
    ];
    return (
        <div className="mb-16">
            {content === 'APropos' &&
                <AProposTeam/>
            }
            {content === 'quotingSteps' &&
                <Cards
                    cardType="card"
                    cards={cards}
                    titleBgColor='bg-secondary'
                    cardsPerRow={{md: 2, lg: 2, xl: 3}}
                    padX={5}
                    gapX={2}
                    gapY={2}
                />
            }
        </div>
    )
}

export default TopAdditionalContent;