import H1Base from "../../components/ui/titles/H1Base";
import H2Base from "../../components/ui/titles/H2Base";
import CardLogo from "../../components/ui/cards/CardLogo";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchHomeIndex} from "../../services/newApi";
import LandingCards from "./components/LandingCards";
import LandingForm from "./components/LandingForm";
import LandingProjects from "./components/LandingProjects";
import TrustPilotCarousel from "../Home/components/TrustPilotCarousel";

const Landing = () => {


    // Injection du script du carousel de Trustpilot dans la page
    const isScriptOnPage = !!document.querySelector('script[src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"]');
    if (!isScriptOnPage) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        document.head.appendChild(script);
    }

    return <main>
        <H1Base text={"Dynamisez votre épargne jusqu'à 11%"} className="mt-16 mb-20 text-center"/>
        <section className={"mb-16 flex flex-col items-center text-center md:text-left md:flex-row md:items-start"}>
                <div className={"md:mr-16 md:w-3/5"}>
                    <div className={"mb-10"}>
                        <H2Base text={"Soutenez l'économie locale en prêtant aux entreprises créatrices d'emplois"} tailwindClass={"text-2xl"} />
                        <p className={"mb-6 text-lg"}>En mettant directement les prêteurs en contact avec les entreprises, nous rendons possible le financement de l'économie réelle tout en offrant un rendement attractif au Prêteur. </p>
                    </div>
                    <LandingCards className={"mb-12 flex flex-col gap-y-9 md:mb-0 md:flex-row justify-between"} />
                </div>
                <div className={"md:w-2/5"}>
                    <LandingForm className={"flex justify-center md:block"}/>
                </div>
        </section>
        <section className={"mb-16"}>
            <LandingProjects />
        </section>
            <TrustPilotCarousel showTitle={false} />
    </main>
}

export default Landing;