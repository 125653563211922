// Calcule le nombre de colonnes à afficher dans la grille
// en fonction de la taille de l'écran  et du nombre de résultats.
export const getNbColumns = (currentBreakpoint, nbItems, nbMaxCols) => {
    switch (currentBreakpoint) {
        case "sm-":
            return 1;
        case "sm":
            if (nbItems === 1) {
                return 1;
            } else {
                if (nbMaxCols < 2) {
                    return nbMaxCols;
                } else {
                    return 2;
                }
            }
        case "md":
            if (nbItems === 1) {
                return 1;
            } else {
                if (nbMaxCols < 2) {
                    return nbMaxCols;
                } else {
                    return 2;
                }
            }
        case "lg":
            if (nbItems === 1) {
                return 1;
            } else if (nbItems === 2) {
                if (nbMaxCols < 2) {
                    return nbMaxCols;
                } else {
                    return 2;
                }
            } else {
                if (nbMaxCols < 3) {
                    return nbMaxCols;
                } else {
                    return 3;
                }
            }
        case "xl":
            if (nbItems === 1) {
                return 1;
            } else if (nbItems === 2) {
                if (nbMaxCols < 2) {
                    return nbMaxCols;
                } else {
                    return 2;
                }
            } else if (nbItems === 3) {
                if (nbMaxCols < 3) {
                    return nbMaxCols;
                } else {
                    return 3;
                }
            } else {
                if (nbMaxCols < 4) {
                    return nbMaxCols;
                } else {
                    return 4;
                }
            }
        default:
            console.error("Wrong breakpoint in component GridProjects");
            return null;
    }
}

export const isColumnTooLarge = (windowSize, columnSize, nbColumn, gap = 0) => {
    if (gap) {
        if ((gap * (nbColumn - 1) + (columnSize * nbColumn)) > windowSize) {
            return true;
        } else {
            return false;
        }
    } else {
        if (columnSize * nbColumn > windowSize) {
            return true;
        } else {
            return false;
        }
    }

}