import {useState} from 'react';
import DOMPurify from 'dompurify';
import {FaPlus} from "react-icons/fa";
import {FaMinus} from "react-icons/fa";

const FaqArticle = ({article, type}) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleBtnClick = () => {
        setIsClicked(!isClicked);
    };

    return (
        <div className={type === 'preteur' ?
            "faq-article mb-2 p-5 bg-secondary rounded-lg"
            :
            "faq-article mb-2 p-5 bg-primary rounded-lg"
        }>
            <div className="flex justify-between items-center cursor-pointer" onClick={handleBtnClick}>
                <h3 className={isClicked ?
                    "mb-3 text-lg font-bold lg:text-xl"
                    :
                    "text-lg font-bold lg:text-xl"
                }>
                    {article.Titre}
                </h3>
                {isClicked ?
                    <FaMinus />
                    :
                    <FaPlus />
                }
            </div>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(article.Contenu)}}
                 className={isClicked ? "faq-article__content" : "faq-article__content hidden"}/>
        </div>
    );
}
export default FaqArticle;