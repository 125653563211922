import axios from "axios";
import {formatDate} from "../functions/functions";

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const checkMail = async (email) => {
    try {
        await axios.post(`${apiBaseUrl}/checkmail`, {email: email});
        return true;
    } catch (error) {
        return false;
    }
}

export const getArticleFromPretup = async (idArticle) => {
    const response = await axios.get(`${apiBaseUrl}/article/${idArticle}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    })

    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}

export const getFaqArticles = async (type) => {
    const response = await axios.get(`${apiBaseUrl}?route=get_faq_articles&type=${type}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    })

    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}

export const getMenu = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/?route=get_main_menu`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }

}

export const getSimulatorData = async (amount) => {

    try {
        const response = await axios.post(`${apiBaseUrl}/get_simulator_data`, {
            amount: amount,
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }

}

export const sendContactForm = async (formData) => {
    try {
        await axios.post(`${apiBaseUrl}/contact`, {...formData});
        return true;
    } catch (error) {
        return false;
    }
}

export const getBorrowerLocalisation = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/localisation_borrower`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.status === 200) {
            if (Array.isArray(response.data)) {
                return response.data;
            } else if (typeof response.data === 'object') {
                return Object.keys(response.data).map((key) => {
                    return response.data[key]
                });
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }

}

export const getLendersLocalisation = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/localisation_lenders`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.status === 200) {
            if (Array.isArray(response.data)) {
                return response.data;
            } else if (typeof response.data === 'object') {
                return Object.keys(response.data).map((key) => {
                    return response.data[key]
                });
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }

}

export const getDepartmentsBoundaries = async () => {

    try {
        const response = await axios.get(`${apiBaseUrl}/department_boundaries`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.status === 200) {
            if (Array.isArray(response.data)) {
                return response.data;
            } else if (typeof response.data === 'object') {
                return Object.keys(response.data).map((key) => {
                    return response.data[key]
                });
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }

}

export const getStatisticsTableDatas = async (type) => {
    const response = await axios.get(`${apiBaseUrl}/?route=get_tableau_statistiques`, {
        headers: {
            'Content-Type': 'application/json',
        }
    })

    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
}

export const getDiversificationsDatas = async () => {
    const response = await axios.get(`${apiBaseUrl}/?route=get_nuage_rentabilite_preteurs`, {
        headers: {
            'Content-Type': 'application/json',
        }
    })

    if (response.status === 200) {
        if (Array.isArray(response.data)) {
            return response.data;
        } else if (typeof response.data === 'object') {
            return Object.keys(response.data).map((key) => {
                return response.data[key]
            });
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const getProjects = async (type) => {
    let url = "";

    if (type === "finished" ||
        type === "notstarted" ||
        type === "notfinished" ||
        type === "refunded"
    ) {
        url = `${apiBaseUrl}/?route=get_projects&state=${type}`;
    } else {
        url = `${apiBaseUrl}/?route=get_projects`;
    }

    const response = await axios.get(url)

    if (Array.isArray(response.data.datas)) {
        return response.data.datas.map(project => ({
            ...project,
            DebutCollecte: formatDate(project.DebutCollecte.date),
            FinCollecte: formatDate(project.FinCollecte.date)
        }));
    } else {
        return false;
    }
}

export const searchProjects = async (search) => {
    if (typeof search !== "string") {
        return false;
    }

    const response = await axios.get(`${apiBaseUrl}/?route=get_projects&search=${search}`);

    if (Array.isArray(response.data.datas)) {

        return response.data.datas.map(project => ({
            ...project,
            DebutCollecte: formatDate(project.DebutCollecte.date),
            FinCollecte: formatDate(project.FinCollecte.date)
        }));
    } else {
        return false;
    }
}


/**
 * Cette fonction est nécessaire car les projets
 * sont obtenus d'une manière différente que celle
 * utilisée dans la methode getProject.
 *
 * @returns {Promise<*|boolean>}
 */
export const fetchProjectsHomeCarousel = async () => {
    const response = await axios.get(`${apiBaseUrl}/?route=get_projects&index=1&limit=30`);

    const projects = response.data.datas;

    if (Array.isArray(projects)) {
        return projects.map(project => ({
            ...project,
            DebutCollecte: formatDate(project.DebutCollecte.date),
            FinCollecte: formatDate(project.FinCollecte.date)
        }));
    } else {
        return false;
    }

}





      