import {useLocation} from "react-router-dom";
import {getFaqArticles} from "../../services/newApi";
import './styles/Faq.css'
import FaqArticle from "./components/FaqArticle";
import Loader from "../../components/Loader";
import {useQuery} from "@tanstack/react-query";
import ErrorWrapper from "../../components/ui/wrappers/ErrorWrapper";

/**
 * Cette page est utilisée à la fois pour la faq preteur et emprunteur.
 * @returns {Element}
 * @constructor
 */
const Faq = () => {
    const location = useLocation();
    let typeFac = '';

    switch (location.pathname) {
        case '/emprunter/faq-emprunteur':
            typeFac = 'emprunteur';
            break;
        case '/preter/faq-preteur':
            typeFac = 'preteur';
            break;
        default:
            typeFac = null;
    }

    const {
        isPending: isLoading,
        data,
        error,
        isError
    } = useQuery({
        queryKey: ["articles-faq", typeFac],
        queryFn: async () => getFaqArticles(typeFac),
    });
    const header = data?.header || [];
    const articles = data?.articles || [];

    if (isLoading) {
        return <Loader/>;
    }

    if (isError) {
        return <ErrorWrapper error={error.message}/>;
    }

    return <>
        {header.length > 0 && header.map((item, index) => {
            return <div
                key={`header-template-${index}`}
                dangerouslySetInnerHTML={{__html: item.Contenu}}
                className={typeFac === "preteur" ?
                    "faq-header faq-header--preter mb-8"
                    :
                    "faq-header faq-header--emprunter mb-8"
                }
            />
        })
        }

        {articles.length > 0 &&
            articles.map((article, index) => {
                return <FaqArticle
                    article={article}
                    key={`article-faq-preteur_${index}`}
                    type={typeFac}
                />
            })
        }
    </>
}

export default Faq;