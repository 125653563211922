import { GrValidate } from "react-icons/gr";
import { FaTrophy } from "react-icons/fa";
import { FaPercent } from "react-icons/fa";
import { FaMoneyBillTrendUp} from "react-icons/fa6";
import {FaUsers} from "react-icons/fa6";
import {FaArrowUpRightDots} from "react-icons/fa6";
import {FaShareFromSquare} from "react-icons/fa6";
import {FaRegHourglassHalf} from "react-icons/fa6";

const icons = {
    "GrValidate": GrValidate,
    "FaTrophy": FaTrophy,
    "FaPercent": FaPercent,
    "FaMoneyBillTrendUp": FaMoneyBillTrendUp,
    "FaUsers": FaUsers,
    "FaArrowUpRightDots": FaArrowUpRightDots,
    "FaShareFromSquare": FaShareFromSquare,
    "FaRegHourglassHalf": FaRegHourglassHalf,
}
const CardLogo = ({logoClass, title, text, isEmprunteur}) => {
    const IconComponent = icons[logoClass];
// console.log("iconComponent", logoClass);
    return (
        <div className='text-center flex flex-col items-center'>
            <IconComponent size={60} className={`mb-2 ${isEmprunteur ? 'text-primary' : 'text-secondary'}`} />
            <h3 className={`font-bold text-xl ${isEmprunteur ? 'text-primaryLight' : 'text-secondaryLight'}`}>{title}</h3>
            <p className={`${isEmprunteur ? 'text-primaryLight' : 'text-secondaryLight'}`}>{text}</p>
        </div>
    )
}
export default CardLogo;