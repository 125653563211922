import React from 'react';


import CardLogo from "../../../components/ui/cards/CardLogo";
import {useQuery} from "@tanstack/react-query";
import {fetchHomeIndex} from "../../../services/newApi";
import Loader from "../../../components/Loader";
import ErrorWrapper from "../../../components/ui/wrappers/ErrorWrapper";

const InfosProjects = () => {
    const {
        isPending: isLoading,
        data,
        error,
        isError
    } = useQuery({
        queryKey: ["index-homepage"],
        queryFn: fetchHomeIndex,
    });

    if (isLoading) {
        return <div className="mb-12">
            <Loader/>
        </div>
    }

    if (isError) {
        return <div className="mb-12">
            <ErrorWrapper error={error?.message || ""} />
        </div>
    }

    return (
        <div className="mb-12">
            <div className='px-[30px] py-6 max-w-[1170px] mx-auto flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 relative lg:-top-4 lg:shadow-1 bg-white lg:bg-transparent lg:backdrop-blur rounded-lg'>
                <CardLogo
                    logoClass="GrValidate"
                    title={data.NbProjetsFinances}
                    text="Projets financés depuis 2015"
                    isEmprunteur={false}
                    />
                <CardLogo
                    logoClass="FaTrophy"
                    title={data.ProjetsRembourses}
                    text="Projets remboursés"
                    isEmprunteur={false}
                />
                <CardLogo
                    logoClass="FaPercent"
                    title={`${parseFloat(data.TauxMoyenPret)?.toFixed(2)}%`}
                    text="Taux moyen des projets"
                    isEmprunteur={false}
                />
                <CardLogo
                    logoClass="FaMoneyBillTrendUp"
                    title="20 €"
                    text="Pour commencer à prêter"
                    isEmprunteur={false}
                />
            </div>
        </div>
    )
}

export default InfosProjects;