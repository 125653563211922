import Button from "../../../components/ui/buttons/Button";
import React from "react";
import TrustPilotReview from "./TrustPilotReview";

const pretupClientSpace = process.env.REACT_APP_USER_DASHBOARD;


/**
 * Calls To Action pour inciter à rejoindre Pretup.
 * @returns {Element}
 * @constructor
 */
const CTAInvest = () => {
    return (
        <div className="text-center mb-12 mx-auto max-w-2xl px-4 sm:px-6 md:mb-14 lg:max-w-7xl lg:px-8">
            <h3 className="mb-8 text-xl font-semibold">Devenez des investisseurs engagés dans l’économie réelle dès 20€</h3>
            <div className="mb-10">
            <Button
                text="Commencez à investir"
                link={{external: true, href: `${pretupClientSpace}/login.php`}}
                tailwinClass="mb-10 sm:mb-0 sm:mr-10"
                btnType="secondary"
                hover={true}
            />
            <Button
                text="En savoir plus"
                link={{external: false, href: '/preter/preter-a-des-entreprises'}}
                btnType="secondaryBorder"
                hover={true}
            />
            </div>
        </div>
    )
}

export default CTAInvest;